import axios from "axios";
import { toastApiError } from "../utils/toastApiError";

function fetchData ({ url, onSuccess, onError, onCallback }) {
  const source = axios.CancelToken.source();
  const token = window?.localStorage?.getItem("accessToken");
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    })
    .then(onSuccess)
    .catch((err) => {
      if (err?.response?.status === 401) {
        // expired token, reload rather than navigating to logout,
        // this will open the login page on the current URL, so the
        // user can return to the same page
        setTimeout(() => window.location.reload(), 2000);
      } else if (onError) {
        onError(err);
      } else {
        toastApiError({ ...err, url });
      }
    })
    .finally(onCallback);

  return () => source.cancel();
}

export default fetchData;
