import { Grid, styled, Typography } from "@mui/material";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import { DescriptionSide } from "../../../../new_components/data-row-container/description-side";

const StepperContainer = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  gap: theme.spacing(4),
  borderLeftWidth: 1,
  borderLeftStyle: "solid",
  borderLeftColor: theme.palette.surface.border.beige,
  padding: theme.spacing(3, 0),
}));

const StepperItem = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  position: "relative",
  left: "-2px",
  "&::before": {
    content: "\"\"",
    display: "block",
    position: "relative",
    width: 3,
    background: theme.palette.surface.border.beige,
    borderRadius: 1,
    margin: theme.spacing(-0.75, 0),
  },
}));

const CommissionDescription = () => {
  return (
    <DescriptionSide item gap={2}>
      <Grid container gap={3}>
        <Typography
          variant="h2">Commission<br /> <UnderlinedHeaderTextWord>Structure</UnderlinedHeaderTextWord></Typography>
        <Typography variant="subtitle2">Supercharge your revenue with our generous commission structure; more players,
          more cash in your wallet.</Typography>
      </Grid>
      <StepperContainer container>
        <StepperItem variant="subtitle2">Up to 50% Revenue Share</StepperItem>
        <StepperItem variant="subtitle2">Fair Commission Structure</StepperItem>
        <StepperItem variant="subtitle2">Start Earning Today</StepperItem>
      </StepperContainer>
    </DescriptionSide>
  );
};

export default CommissionDescription;