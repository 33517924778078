import { Accordion, AccordionDetails, AccordionSummary, Box, Link, styled, Typography } from "@mui/material";
import { useState } from "react";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import WhiteButton from "../../../../new_components/buttons/white-button";

const BoxContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "&.Mui-expanded": {
    margin: 0,
    "&::before": {
      opacity: 1,
    },
    "& > div > .MuiAccordionSummary-content": {
      marginBottom: "unset",
    },
  },
  "&:first-of-type": {
    borderTopLeftRadius: theme.spacing(4),
    borderTopRightRadius: theme.spacing(4),
  },
  "&:last-of-type": {
    borderBottomLeftRadius: theme.spacing(4),
    borderBottomRightRadius: theme.spacing(4),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0, 6),
  "& > .MuiAccordionSummary-content": {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
  },
}));


const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}));

const panelData = [
  {
    id: "panel1",
    summary: "Who are we?",
    details: "Vegas Affiliate is an affiliate network with over 30 years of collective experience that seeks partners to provide traffic for our casino and bingo sites. We're looking to establish long term, mutually beneficial relationships with affiliates.",
  },
  {
    id: "panel2",
    summary: "How can I earn money?",
    details: "We pay affiliates for every player they send, which means the better your traffic, the more commission you earn. Every time you send a player who deposits money on one of our sites you can earn commission. The type of commission you earn depends on the deal we negotiate.",
  },
  {
    id: "panel3",
    summary: "Why should I choose Vegas Affiliate?",
    details: "There are several benefits to being a member of the Vegas Affiliate program. Including our large portfolio of available casino and bingo brands, and our generous commission structure, we also have a dedicated team of affiliate managers offering guidance and support. We provide affiliates with a variety of data reports so they can assess their own performance.",
  },
  {
    id: "panel4",
    summary: "How does the sign-up process work?",
    details: "You can sign-up to become a member of our affiliate programme. All you have to do is provide us with the information required. From there our team will assess your application and be in touch!",
  },
  {
    id: "panel5",
    summary: "How can I track my earnings?",
    details: "We provide a number of reports which help affiliates track commission daily, monthly and per player. Our user interface provides affiliates with transparent reports so they can see their true performance.",
  },
  {
    id: "panel6",
    summary: "Are there any sign-up costs or hidden fees?",
    details: "Put simply, no. There are no costs in joining our platform. All you have to do is provide us with casino players. ",
  },
];

const AccordionPanel = ({ id, summary, details, expanded, onChange }) => (
  <StyledAccordion expanded={expanded === id} onChange={onChange(id)}>
    <StyledAccordionSummary
      expandIcon={expanded !== id ? <AddRounded /> : <RemoveRounded />}
      aria-controls={`${id}-content`}
      id={`${id}-header`}>
      <Typography variant="faqAccordionHeading">{summary}</Typography>
    </StyledAccordionSummary>
    <StyledAccordionDetails>
      {typeof details === "string" ? <Typography variant="subtitle2">{details}</Typography> :
        details.map((item) => item)
      }
      <WhiteButton sx={(theme) => ({ boxShadow: "none", marginTop: theme.spacing(1.5) })}>See more</WhiteButton>
    </StyledAccordionDetails>
  </StyledAccordion>
);

const Spoiler = () => {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <BoxContainer>
      {panelData.map(item => (
        <AccordionPanel
          key={item.id}
          id={item.id}
          summary={item.summary}
          details={item.details}
          expanded={expanded}
          onChange={handleChange}
        />
      ))}
    </BoxContainer>
  );
};

export default Spoiler;