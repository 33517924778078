import AppRouter from "./router";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { customTheme } from "./theme";
import { Toaster } from "react-hot-toast";

const theme = customTheme({
  responsiveFontSizes: false,
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            maxWidth: "100%"
          }
        }}
      />
      <CssBaseline />
      <AppRouter />
    </ThemeProvider>
  );
};

export default App;