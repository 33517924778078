import { styled } from "@mui/material";

const StyledSpan = styled("span", {
  shouldForwardProp: (prop) => prop !== "customLinesPosition",
})(({ theme, customLinesPosition }) => ({
  position: "relative",
  zIndex: 1,
  display: "inline-block",
  "&::before": {
    content: "\"\"",
    display: "block",
    width: `calc(100% + ${theme.spacing(1)})`,
    height: theme.spacing(1),
    background: theme.palette.primary.main,
    position: "absolute",
    left: theme.spacing(-0.5),
    bottom: customLinesPosition || theme.spacing(0.5),
    zIndex: -1,
  },
  "&::after": {
    content: "\"\"",
    display: "block",
    width: theme.spacing(1),
    height: theme.spacing(1),
    background: theme.palette.primary.main,
    position: "absolute",
    left: `calc(100% + ${theme.spacing(1)})`,
    bottom: customLinesPosition || theme.spacing(0.5),
    zIndex: -1,
  },
}));


const UnderlinedHeaderTextWord = ({ children, customLinesPosition }) => {
  return (
    <StyledSpan customLinesPosition={customLinesPosition}>{children}</StyledSpan>
  );
};

export default UnderlinedHeaderTextWord;