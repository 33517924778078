import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Alert, AlertTitle, Button, CircularProgress, Grid } from "@mui/material";
import "./UserSettings.scss";
import fetchData from "../../api/data";

const UserSettings = () => {
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
    referralCode: "",
    skype: "",
    website: "",
  });
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isSaving, setSaving] = useState(false);
  const token = window?.localStorage?.getItem("accessToken");

  const customValidate = useCallback((values) => {
    const valuesArray = Object.entries(values);
    if (valuesArray.length !== 0) {
      valuesArray.map(value => {
        switch (value[0]) {
          case "firstName":
            if (value[1].length > 15 || value[1].length < 3) {
              const newMessages = messages;
              newMessages.push({ field: value[0], text: "First Name must be 15 characters or less" });
              setMessages(newMessages);
            }
            break;
          case "lastName":
            if (value[1].length > 20 || value[1].length < 3) {
              const newMessages = messages;
              newMessages.push({ field: value[0], text: "Last Name must be 15 characters or less" });
              setMessages(newMessages);
            }
            break;
          case "email":
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value[1])) {
              const newMessages = messages;
              newMessages.push({ field: value[0], text: "Invalid email address" });
              setMessages(newMessages);
            }
            break;
          case "companyName":
            if (value[1].length > 30 || value[1].length < 3) {
              const newMessages = messages;
              newMessages.push({ field: value[0], text: "Company must be 3 characters or more" });
              setMessages(newMessages);
            }
            break;
          case "phoneNumber":
            if (value[1].length > 15 || value[1].length < 10) {
              const newMessages = messages;
              newMessages.push({ field: value[0], text: "Phone must be 10 characters or more" });
              setMessages(newMessages);
            }
            break;
          case "skype":
            if (value[1].length < 3) {
              const newMessages = messages;
              newMessages.push({ field: value[0], text: "Skype must be 3 characters or more" });
              setMessages(newMessages);
            }
            break;
          case "website":
            if (value[1].length < 3) {
              const newMessages = messages;
              newMessages.push({ field: value[0], text: "Website must be 3 characters or more" });
              setMessages(newMessages);
            }
            break;
          default:
            break;
        }
        return null;
      });
    }
  }, [messages, setMessages]);

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: "/api/v3/user/settings",
      onSuccess: (resp) => {
        const { email, firstName, lastName, companyName, phoneNumber, referralCode, skype, website } = resp.data;
        setUserData({
          email, firstName, lastName, companyName, phoneNumber, referralCode, skype, website
        });
        formik.initialValues = { email, firstName, lastName, companyName, phoneNumber, referralCode, skype, website };
      },
      onCallback: () => setLoading(false),
    });
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      if (!Object.keys(values).length) return;

      setError(false);
      setSuccess(false);
      customValidate(values);

      if (messages.length === 0) {
        setSaving(true);
        const affiliate = {
          ...userData,
          ...values,
        };
        axios({
          url: `/api/v3/user/settings`,
          method: "put",
          data: {
            affiliate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(() => {
            setSuccess(true);
            setSaving(false);
          })
          .catch((error) => {
            console.log(error);
            setError(true);
            setSaving(false);
          });
      } else {
        setError(true);
      }
    },
  });

  return (
    <>
      <h4 className="settings__title">User Settings</h4>
      {isLoading && <CircularProgress />}
      {!isLoading ? (
        <div className="profile__form">
          {success && (
            <Alert severity="success">Update user information is successful</Alert>)}
          <form className="signup-form" onSubmit={formik.handleSubmit}>
            {error ?
              <Alert severity="error">Update user information has failed or there is nothing to update</Alert> : null}
            {messages.length !== 0 && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {messages.map(message => (<span key={message.field} className="alert--error">{message.text}</span>))}
              </Alert>
            )}
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="firstName">First Name</label>
              <input type="text" name="firstName" id="firstName" defaultValue={userData.firstName}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="lastName">Last Name</label>
              <input type="text" name="lastName" id="lastName" defaultValue={userData.lastName}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="email">Email</label>
              <input type="text" disabled name="email" id="email" defaultValue={userData.email}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="companyName">Company name</label>
              <input type="text" name="companyName" id="companyName" defaultValue={userData.companyName}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="phoneNumber">Phone number</label>
              <input type="text" name="phoneNumber" id="phoneNumber" defaultValue={userData.phoneNumber}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="referralCode">Your referral code</label>
              <input type="text" disabled name="referralCode" defaultValue={userData.referralCode}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="skype">Skype</label>
              <input type="text" name="skype" id="skype" defaultValue={userData.skype} onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="website">Website</label>
              <input type="text" name="website" id="website" defaultValue={userData.website}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
                id={"save-button"}
                disabled={isSaving}
                onClick={() => {
                  setMessages([]);
                }}
              >
                Save
              </Button>
            </Grid>
          </form>
        </div>
      ) : error}
    </>
  );
};

export default UserSettings;
