import { SvgIcon } from "@mui/material";

const AffiliateIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink"
             width="24" height="24" x="0px" y="0px"
             viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve">
      <g>
        <g>
          <path d="M467,211c-19.555,0-36.228,12.541-42.42,30h-34.418c-2.888-25.999-13.186-49.792-28.733-69.216l23.674-23.674
			c16.804,8.006,37.433,4.993,51.209-8.783c17.587-17.586,17.588-46.052,0-63.64c-17.544-17.545-46.094-17.546-63.639,0
			c-14.032,14.032-16.699,34.73-8.801,51.228l-23.655,23.656C320.792,135.024,297,124.725,271,121.838V87.42
			c17.459-6.192,30-22.865,30-42.42c0-24.813-20.187-45-45-45s-45,20.187-45,45c0,19.555,12.541,36.228,30,42.42v34.418
			c-26,2.888-49.792,13.186-69.216,28.733l-23.655-23.656c7.958-16.624,5.129-37.298-8.801-51.228
			c-17.544-17.545-46.094-17.546-63.639,0c-17.587,17.586-17.588,46.052,0,63.64c13.841,13.842,34.48,16.749,51.208,8.782
			l23.675,23.675C135.026,191.208,124.727,215,121.839,241H87.42c-6.192-17.459-22.865-30-42.42-30c-24.813,0-45,20.187-45,45
			s20.187,45,45,45c19.555,0,36.228-12.541,42.42-30h34.418c2.888,25.999,13.186,49.792,28.733,69.216l-23.675,23.675
			c-16.724-7.967-37.382-5.045-51.208,8.782c-17.587,17.586-17.588,46.052,0,63.64c17.559,17.56,46.109,17.531,63.639,0
			c14.03-14.03,16.7-34.728,8.801-51.228l23.655-23.656C191.208,376.976,215,387.275,241,390.162v34.418
			c-17.459,6.192-30,22.865-30,42.42c0,24.813,20.187,45,45,45s45-20.187,45-45c0-19.555-12.541-36.228-30-42.42v-34.418
			c26-2.887,49.792-13.186,69.216-28.733l23.655,23.656c-7.958,16.625-5.128,37.298,8.801,51.228
			c17.559,17.56,46.109,17.531,63.639,0c17.587-17.586,17.588-46.052,0-63.64c-13.826-13.827-34.484-16.749-51.208-8.782
			l-23.675-23.675C376.976,320.792,387.275,297,390.161,271h34.418c6.192,17.459,22.865,30,42.42,30c24.813,0,45-20.187,45-45
			S491.813,211,467,211z M393.886,96.901c5.848-5.848,15.365-5.849,21.213,0c5.862,5.862,5.863,15.35,0,21.213
			c-5.849,5.849-15.365,5.848-21.213,0C388.023,112.251,388.023,102.764,393.886,96.901z M118.115,118.114
			c-5.848,5.849-15.364,5.848-21.213,0c-5.862-5.862-5.863-15.35,0-21.213c5.848-5.848,15.365-5.849,21.213,0
			C123.977,102.764,123.977,112.251,118.115,118.114z M45,271c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15
			S53.271,271,45,271z M118.115,415.099c-5.849,5.849-15.365,5.848-21.213,0c-5.862-5.862-5.863-15.35,0-21.213
			c5.848-5.848,15.365-5.849,21.213,0C123.977,399.749,123.977,409.236,118.115,415.099z M393.886,393.886
			c5.848-5.848,15.364-5.849,21.213,0c5.862,5.862,5.863,15.35,0,21.213c-5.849,5.849-15.365,5.848-21.213,0
			C388.023,409.236,388.023,399.749,393.886,393.886z M256,30c8.271,0,15,6.729,15,15s-6.729,15-15,15s-15-6.729-15-15
			S247.729,30,256,30z M256,482c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S264.271,482,256,482z M256,361
			c-24.499,0-47.063-8.435-64.946-22.552C204.328,315.41,228.826,301,256,301s51.673,14.41,64.946,37.448
			C303.063,352.565,280.5,361,256,361z M226,241c0-16.542,13.458-30,30-30s30,13.458,30,30s-13.458,30-30,30S226,257.542,226,241z
			 M342.174,315.92c-10.58-15.236-24.903-27.239-41.438-34.992C310.221,270.313,316,256.321,316,241c0-33.084-26.916-60-60-60
			s-60,26.916-60,60c0,15.321,5.78,29.313,15.265,39.928c-16.535,7.753-30.858,19.756-41.437,34.992
			C157.967,298.916,151,278.258,151,256c0-57.897,47.103-105,105-105c57.897,0,105,47.103,105,105
			C361,278.258,354.034,298.916,342.174,315.92z M467,271c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15
			S475.271,271,467,271z" />
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </SvgIcon>

  );
};

export default AffiliateIcon;