import { Grid, styled } from "@mui/material";

export const CardContainer = styled(Grid)(({ theme }) => ({
  width: theme.spacing(92.5),
  height: theme.spacing(52.75),
  margin: "auto",
  borderRadius: theme.spacing(3),
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.surface.border.secondary,
  background: theme.palette.background.main,
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}));

export const PreStyledMainCard = styled(CardContainer)(({ theme }) => ({
  background: theme.palette.background.white,
  zIndex: 2,
}));

const CardsData = styled(Grid)(({ theme }) => ({
  width: theme.spacing(99.5),
  height: theme.spacing(63.125),
  position: "relative",
  "@media(max-width: 787px)": {
    zoom: 0.8,
  },
  "@media(max-width: 640px)": {
    zoom: 0.45,
  },
}));

const CardsDataContainer = ({ children }) => {
  return (
    <CardsData container>
      <>{children}</>
      <CardContainer sx={{ transform: "rotate(-2deg)", zIndex: 1 }} />
      <CardContainer sx={{ transform: "rotate(-6deg)" }} />
    </CardsData>
  );
};

export default CardsDataContainer;