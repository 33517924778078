import styled from 'styled-components';

export const Button = styled.div`
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 40px;
    color: #4152FD;
    cursor: pointer;

    i{
        filter: drop-shadow(0px 0px 8px #fff);
    }

    &:hover{
        filter: saturate(2);
    }

    @media (max-width: 600px){
        font-size: 35px;
    }
`