import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import Search from "../../components/Search/Search";
import Export from "../../components/Export/Export";
import "./Players.scss";
import { applyCurrencyFormatters } from "../../common/helper";
import fetchData from "../../api/data";
import { Dashboard } from "../../components/Dashboard/Dashboard";
import { toastApiError } from "../../utils/toastApiError";
import useAuth from "../../auth/useAuth";

const customStyles = {
  header: {
    style: {
      fontSize: "24px",
      fontWeight: "800",
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
};

const perPageList = [10, 50, 100];

const Players = ({ datesFilter, brandsFilter }) => {
  const auth = useAuth();
  const [reports, setReport] = useState([]);
  const [stats, setStats] = useState({});
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const query = search ? `search=` + encodeURIComponent(search) : "";
    const fetchUrl = `/api/v3/reports/players?${datesFilter}&${brandsFilter}&${query}`;

    setLoading(true);
    return fetchData({
      url: fetchUrl,
      onSuccess: (response) => {
        setReport(response.data.reports);
        setStats(response.data.totalsReport);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [search, datesFilter, brandsFilter]);

  const columns = [
    {
      name: "Player ID",
      selector: (row) => row.playerId,
      sortable: true,
      grow: 3,
      style: {
        fontSize: "14px",
      },
    },
    {
      name: "Casino",
      selector: (row) => row.brandName,
      sortable: true,
      grow: 2,
      style: {
        fontSize: "14px",
      },
    },
    {
      name: "FTDs",
      selector: (row) => row.ftds,
      sortable: true,
      right: true,
    },
    {
      name: "No of deposits",
      selector: (row) => row.numberOfDeposits,
      sortable: true,
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Deposits",
      selector: (row) => row.deposits,
      sortable: true,
      format: "currency",
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Net Cash",
      selector: (row) => row.netCash,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: "Rev Share Commission",
      selector: (row) => row.revShareCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: "CPA Commission",
      selector: (row) => row.cpaCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
  ];

  return (
    <Row>
      <Dashboard stats={stats} />
      <div className="data__wrapper">
        <Search searchQuery={setSearch} />
        <DataTable
          title="Players Report"
          columns={applyCurrencyFormatters(columns)}
          data={reports}
          progressPending={loading}
          customStyles={customStyles}
          pagination
          paginationRowsPerPageOptions={perPageList}
          progressComponent={<Circular />}
          actions={<Export rows={reports} />}
        />
      </div>
    </Row>
  );
};

export default connectTopLine(true, true, Players);
