import React, { useState } from "react";

interface ContextData {
  brands: number[];
  startDate: Date;
  endDate: Date;
  updateContext: (data: PartialContextData) => void;
}

type PartialContextData = Partial<ContextData>;

const defaultValue: ContextData = {
  brands: [] as number[],
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate: new Date(),
  updateContext: () => {}
};

export const ReportContext = React.createContext(defaultValue);

export const ReportContextProvider = ({ children }: { children: any }) => {
  const [items, setItems] = useState<ContextData>(defaultValue);

  const update = (newData: PartialContextData) => {
    setItems({
      ...items,
      ...newData,
      updateContext: update,
    });
  };

  return (
    <>
      <ReportContext.Provider value={{ ...items, updateContext: update }}>
        {children}
      </ReportContext.Provider>
    </>
  );
};
