import { Grid, styled, Typography, useTheme } from "@mui/material";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import { useState } from "react";
import Bohdan from "../../../../new_assets/images/team/Bohdan.png";
import Phil from "../../../../new_assets/images/team/Phil.png";
import Kate from "../../../../new_assets/images/team/Kate.png";

const StyledHeading = styled(Typography)(({ theme }) => ({
  maxWidth: theme.spacing(48),
  fontSize: "clamp(2.5rem, -0.9091rem + 14.5455vw, 3rem)",
}));

const TeamContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(15.5),
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(10),
  },
}));

const TeamRow = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(6),
  gap: theme.spacing(2),
  justifyContent: "center",
  "@media(max-width: 980px)": {
    justifyContent: "flex-start",
    overflow: "auto",
  },
  "& > div": {
    transition: "width 0.1s linear",
    "&:last-of-type": {
      flexGrow: 1,
    },
  },
}));

const TeamMember = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isExpanded",
})(({ theme, isExpanded }) => ({
  width: isExpanded ? theme.spacing(79) : theme.spacing(38.5),
  maxWidth: theme.spacing(79),
  minWidth: theme.spacing(38.5),
  height: theme.spacing(50),
  borderRadius: theme.spacing(2),
  background: theme.palette.background.darkBeige,
  overflowX: "hidden",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    maxWidth: theme.spacing(38.5),
  },
  "@media(max-width: 705px)": {
    zoom: 0.85,
  },
}));

const TeamMemberCover = styled(Grid)(({ theme }) => ({
  position: "absolute",
  width: theme.spacing(79),
  height: theme.spacing(43),
  padding: theme.spacing(5),
  right: 0,
  "&::before": {
    content: "\"Affiliate\"",
    ...theme.typography.cardBackgroundText,
    color: theme.palette.text.contrastText,
    position: "absolute",
    transform: "rotate(-90deg) translate(-33%, 150%)",
    textTransform: "uppercase",
    height: "fit-content",
    opacity: 0.05,
    right: 0,
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    padding: theme.spacing(3),
  },
}));

const EmailCover = styled(Grid)(({ theme }) => ({
  position: "absolute",
  width: theme.spacing(79),
  bottom: 0,
  [theme.breakpoints.up("lg")]: {
    right: 0,
  },
}));

const StyledEmail = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  transform: "translate(12%, -100%)",
  [theme.breakpoints.down("lg")]: {
    transform: "translate(10%, -100%)",
  },
}));

const Photo = styled("img")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  [theme.breakpoints.down("lg")]: {
    // position: "relative",
    maxWidth: theme.spacing(28.25),
  },
}));

const team = [
  {
    name: "Bohdan",
    email: "affiliates@vegasaffiliate.com",
    photo: Bohdan,
  },
  {
    name: "Phil",
    email: "affiliates@vegasaffiliate.com",
    photo: Phil,
  },
  {
    name: "Kate",
    email: "affiliates@vegasaffiliate.com",
    photo: Kate,
  },
];

const Team = () => {
  const theme = useTheme();
  const [hoveredIndex, setHoveredIndex] = useState(0);

  return (
    <TeamContainer container>
      <StyledHeading variant="h2"><UnderlinedHeaderTextWord>Meet the team</UnderlinedHeaderTextWord></StyledHeading>
      <TeamRow container wrap="nowrap">
        {team.map((item, index) => (
          <TeamMember
            item
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(0)}
            isExpanded={hoveredIndex === index}
            style={{
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
          >
            <div style={{
              background: theme.palette.background.darkBlue,
              height: theme.spacing(43),
              borderBottomLeftRadius: theme.spacing(4),
              borderBottomRightRadius: theme.spacing(4),
              overflow: "hidden",
              position: "relative",
            }}>
              <TeamMemberCover container flexDirection="column">
                <Typography variant="h2" color="surface.border.main">{item.name}</Typography>
                <Typography variant="subtitle2" color="primary.main">Affiliate Manager</Typography>
                <Photo src={item.photo} />
              </TeamMemberCover>
            </div>
            <EmailCover>
              <StyledEmail>{item.email}</StyledEmail>
            </EmailCover>
          </TeamMember>
        ))}
      </TeamRow>
    </TeamContainer>
  );
};

export default Team;