import PropTypes from "prop-types";

const Title = ({ title = "Simple title", classes = "page__title" }) => {
  return <h1 className={classes}>{title}</h1>;
};

Title.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
};

export default Title;
