import { Navigate } from "react-router-dom";
import useAuth from "../auth/useAuth";
import { Alert, Box, Container, Typography } from "@mui/material";
import "../App.css";

const unconfirmedBox = () => (
  <>
    <Container className={"center-block"} maxWidth="lg" sx={{ height: "calc(100vh - 212px - 82px - 76px - 64px)" }}>
      <Alert severity="success">
        <Typography>Login Successful - your account is awaiting confirmation from an affiliate manager</Typography>
      </Alert>
    </Container>
  </>
);

export const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  const hasToken = Boolean(window.localStorage.getItem("accessToken"));

  if (!auth.isAuthenticated) {
    if (hasToken) {
      return <Box />;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  if (!auth.user?.confirmed) {
    return unconfirmedBox();
  }

  return children;

};
