import { SvgIcon } from "@mui/material";

const MountainGraphIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
      <g clipPath="url(#clip0_279_1660)">
        <path
          d="M24.3724 23.7765C24.6739 23.8461 24.8636 24.1497 24.794 24.4511L24.3722 26.2781C24.3026 26.5795 23.999 26.7692 23.6976 26.6996L1.40888 21.5539C1.10743 21.4843 0.917734 21.1807 0.987328 20.8792L4.78338 4.43676C4.85297 4.13531 5.15655 3.94561 5.458 4.01521L7.28494 4.43699C7.58638 4.50658 7.77608 4.81016 7.70649 5.11161L4.45876 19.1791L24.3724 23.7765ZM20.9255 12.1805L16.4611 13.8679L13.7641 7.77536C13.6029 7.411 13.1226 7.32417 12.8446 7.61119L7.35437 13.3049L6.25774 18.0549L23.7964 22.1041L21.6706 12.5786C21.593 12.248 21.2417 12.061 20.9255 12.1805Z"
          fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_279_1660">
          <rect width="24" height="24" fill="white" transform="translate(5.58472 0.96582) rotate(13)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default MountainGraphIcon;