import { Button, Menu as MenuMaterial, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import useAuth from "../../auth/useAuth";
import { PersonRounded } from "@mui/icons-material";
import { StyledIconButton } from "../buttons/styled-icon-button";
import WhiteButton from "../buttons/white-button";
import OrangeButton from "../buttons/orange-button";

const UserPanel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenModal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{ textTransform: "unset" }}
        component={"div"}
        disableRipple
        onClick={handleOpenModal}
      >
        <StyledIconButton>
          <PersonRounded />
        </StyledIconButton>
        {!isMobile && <Typography>{auth.user.fullName}</Typography>}
      </Button>
      <MenuMaterial
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseModal}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleCloseModal}>
          <WhiteButton component={Link} to="/admin/overview">Dashboard</WhiteButton>
        </MenuItem>
        <MenuItem onClick={handleCloseModal}>
          <OrangeButton fullWidth onClick={auth.logout}>Logout</OrangeButton>
        </MenuItem>
      </MenuMaterial>
    </>
  );
};

export default UserPanel;