import OrangeButton from "./orange-button";
import SignUpArrow from "../../new_assets/SignUpArrow";
import { useTheme } from "@mui/material";

const OrangeArrowButton = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <OrangeButton
      {...props}
      endIcon={<SignUpArrow sx={{
        "&&": {
          fontSize: theme.spacing(4),
        },
      }} />}>{children}</OrangeButton>
  );

};

export default OrangeArrowButton;