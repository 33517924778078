import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import fetchData from "../../api/data";
import { CalendarMonth } from "@mui/icons-material";
import CalendarIcon from "../../assets/CalendarIcon";
import StatsIcon from "../../assets/StatsIcon";
import ChartIcon from "../../assets/ChartIcon";
import UserIcon from "../../assets/UserIcon";
import CasinoIcon from "../../assets/CasinoIcon";
import AffiliateIcon from "../../assets/AffiliateIcon";
import MessagesIcon from "../../assets/MessagesIcon";
import LinkIcon from "../../assets/LinkIcon";
import PictureIcon from "../../assets/PictureIcon";
import FlashIcon from "../../assets/FlashIcon";
import ProfileIcon from "../../assets/ProfileIcon";

const Navbar = () => {
  const [unreadMessages, setUnreadMessages] = useState(false);

  const handleClick = (e) => {
    const toggleLink = document.querySelectorAll(".toggle-menu");
    toggleLink.forEach((item) => {
      if (item.classList.contains("active-toggle")) {
        item.classList.remove("active-toggle");
      }
    });
    e.target.parentNode.classList.toggle("active-toggle");
  };

  useEffect(() => {
    return fetchData({
      url: "/api/v3/affiliate-messages/all/user/unread",
      onSuccess: (response) => setUnreadMessages(response.data),
    });
  }, []);

  const navLinkClassName = ({ isActive }) => (
    "nav-link" + (isActive ? " is-active" : "")
  );

  return (
    <div className="menu-container">
      <nav className="main-navbar">
        <ul className="main-menu">
          <li className="toggle-menu" onClick={handleClick}>
            <span className="menu-item__title">Dashboard</span>
            <NavLink
              className={navLinkClassName}
              to="/admin/overview"
            >
              <CalendarMonth /> Overview
            </NavLink>
          </li>
          <li className="toggle-menu" onClick={handleClick}>
            <span className="menu-item__title">Reports</span>
            <NavLink
              className={navLinkClassName}
              to="/admin/daily"
            >
              <CalendarIcon /> Daily
            </NavLink>
            <NavLink
              className={navLinkClassName}
              to="/admin/dynamic_variable"
            >
              <StatsIcon /> Variables
            </NavLink>
            <NavLink
              className={navLinkClassName}
              to="/admin/brands"
            >
              <ChartIcon /> Brands
            </NavLink>
            <NavLink
              className={navLinkClassName}
              to="/admin/players_report"
            >
              <UserIcon /> Players
            </NavLink>
            <NavLink
              className={navLinkClassName}
              to="/admin/casinos"
            >
              <CasinoIcon /> Casinos
            </NavLink>
          </li>
          <span className="menu-item__title">Subaffilation</span>
          <li>
            <NavLink
              className={navLinkClassName}
              to="/admin/subaffilation"
            >
              <AffiliateIcon /> Subaffilation
            </NavLink>
          </li>
          <span className="menu-item__title">Messages </span>
          <li>
            {
              unreadMessages ?
                <NavLink
                  className={navLinkClassName}
                  to="/admin/messages"
                  style={{ backgroundColor: "red" }}
                >
                  <MessagesIcon /> Messages (*)
                </NavLink>
                :
                <NavLink
                  className={navLinkClassName}
                  to="/admin/messages"
                >
                  <MessagesIcon /> Messages
                </NavLink>
            }
          </li>
          <span className="menu-item__title">Manage</span>
          <li>
            <NavLink
              className={navLinkClassName}
              to="/admin/campaigns"
            >
              <LinkIcon /> Links
            </NavLink>
            <NavLink
              className={navLinkClassName}
              to="/admin/assets"
            >
              <PictureIcon /> Assets
            </NavLink>
            <NavLink
              className={navLinkClassName}
              to="/admin/reports"
            >
              <FlashIcon /> API Reports
            </NavLink>
          </li>
          <span className="menu-item__title">Settings</span>
          <li>
            <NavLink
              className={navLinkClassName}
              to="/admin/profile/user-settings"
            >
              <ProfileIcon /> Profile
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
