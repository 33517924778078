import { useEffect, useState } from "react";
import { Dashboard } from "../../components/Dashboard/Dashboard";
import { Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import { applyCurrencyFormatters } from "../../common/helper";
import "bootstrap/dist/css/bootstrap.min.css";
import Export from "../../components/Export/Export";
import fetchData from "../../api/data";
import { toastApiError } from "../../utils/toastApiError";
import useAuth from "../../auth/useAuth";

const perPageList = [10, 50, 100];

const Daily = ({ datesFilter, brandsFilter }) => {
  const auth = useAuth();
  const [reports, setReports] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: `/api/v3/reports/daily?${datesFilter}&${brandsFilter}`,
      onSuccess: (response) => {
        setReports(response.data.reports);
        setStats(response.data.totalsReport);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [datesFilter, brandsFilter]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Clicks",
      selector: (row) => row.clicks,
      sortable: true,
      right: true,
    },
    {
      name: "Registrations",
      selector: (row) => row.registrations,
      sortable: true,
      right: true,
    },
    {
      name: "FTDs",
      selector: (row) => row.ftds,
      sortable: true,
      right: true,
    },
    {
      name: "No of deposits",
      selector: (row) => row.numberOfDeposits,
      sortable: true,
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Deposits",
      selector: (row) => row.deposits,
      sortable: true,
      format: "currency",
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: "Net Cash",
      selector: (row) => row.netCash,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: "Rev Share Commission",
      selector: (row) => row.revShareCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: "CPA Commission",
      selector: (row) => row.cpaCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
  ];

  return (
    <Row>
      <Dashboard stats={stats} />
      <div className="data__wrapper">
        <DataTable
          title="Daily"
          columns={applyCurrencyFormatters(columns)}
          data={reports}
          progressPending={loading}
          pagination
          progressComponent={<Circular />}
          paginationRowsPerPageOptions={perPageList}
          actions={<Export rows={reports} />}
        />
      </div>
    </Row>
  );
};

export default connectTopLine(true, true, Daily);
