import { SvgIcon } from "@mui/material";

const PureBetsIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="221" height="89" viewBox="0 20 215 50" fill="none">
      <g clipPath="url(#clip0_196_8156)" transform="translate(0.359009 -10.71) rotate(7)">
        <path
          d="M5.47015 42.9826L21.9051 40.6743C29.4232 39.6184 34.7518 42.8605 35.6049 48.935C36.6222 56.1777 31.4777 60.593 23.4933 61.7144L16.8494 62.6476L17.2688 73.25L6.54532 74.7562L5.47015 42.9826ZM16.6308 55.5906L20.8853 54.993C23.741 54.5919 25.1923 53.0777 24.856 50.683C24.5853 48.7555 23.2975 47.626 20.5 48.019L16.4204 48.5919L16.6308 55.5906Z"
          fill="#FFB800" />
        <path
          d="M63.7169 59.1022C63.7856 62.1301 63.9463 64.9665 64.1341 66.7269L53.702 68.1921C53.62 67.608 53.4641 66.4983 53.3575 65.739C52.0883 67.704 50.3455 69.2592 46.2077 69.8403C40.8459 70.5934 37.4889 67.8486 37.3187 61.5591L36.7922 46.3855L47.4574 44.8875L47.9364 58.8766C48.0232 61.1873 48.6618 62.3484 50.4101 62.1028C52.5082 61.8081 53.2274 60.1585 53.1013 56.7217L52.6361 44.1006L63.243 42.6109L63.7588 58.9772L63.7169 59.1022Z"
          fill="#FFB800" />
        <path
          d="M65.8665 49.8663C65.8142 46.9552 65.7282 44.2275 65.5905 42.4004L75.7894 40.968C75.9207 41.9025 76.1914 43.83 76.2135 45.2564C77.1429 42.5647 79.0876 39.9091 83.8666 39.2379L84.1301 48.7306C79.0852 49.2009 76.6431 50.8543 76.8561 56.6018L77.1304 64.9017L66.4652 66.3997L65.8665 49.8663Z"
          fill="#FFB800" />
        <path
          d="M95.1177 52.4889C95.1177 52.4889 95.1669 52.8394 95.1833 52.9562C95.4787 55.0589 96.6909 56.4968 98.8473 56.1939C100.712 55.932 101.48 55.0499 101.84 53.808L111.923 52.3919C111.14 56.552 108.226 61.1901 99.1923 62.4589C89.1682 63.8668 85.2089 58.5264 84.405 52.8023C83.4698 46.1437 86.7808 38.8292 97.1546 37.3722C106.654 36.038 111.031 40.9624 111.917 46.8535C112.065 47.9049 112.145 49.3231 112.085 50.1653L95.1842 52.5391L95.1177 52.4889ZM101.514 46.4087C101.514 46.4087 101.457 45.9999 101.432 45.8246C101.186 44.0724 100.181 42.8436 98.025 43.1465C95.8687 43.4493 94.9 45.0148 94.9203 47.2753L101.448 46.3585L101.514 46.4087Z"
          fill="#FFB800" />
        <path
          d="M145.059 31.4768C144.427 26.9794 140.504 24.0162 132.87 25.0885L114.628 27.6506L115.711 59.4825L134.711 56.8141C143.045 55.6435 147.623 51.4269 146.761 45.294C146.261 41.7311 143.435 39.8051 140.565 39.2553C143.681 37.7456 145.492 34.9895 145 31.485L145.059 31.4768ZM131.569 50.525L126.091 51.2944L125.87 45.0715L131.29 44.3102C133.913 43.9419 135.184 44.9545 135.422 46.6483C135.734 48.8679 134.425 50.1239 131.569 50.525ZM131.063 37.6118L125.643 38.3731L125.471 32.9176L130.716 32.1809C132.756 31.8945 134.095 32.5403 134.341 34.2925C134.587 36.0448 133.511 37.268 131.063 37.6118Z"
          fill="#242322" />
        <path
          d="M159.979 28.5468C149.664 29.9956 146.286 37.2599 147.23 43.9769C148.042 49.7594 151.935 55.0496 162.017 53.6335C171.051 52.3647 173.957 47.6682 174.748 43.5665L164.665 44.9826C164.305 46.2245 163.537 47.1066 161.672 47.3685C159.516 47.6714 158.312 46.2919 158.008 44.1308C157.992 44.014 157.967 43.8387 157.943 43.6635L174.844 41.2897C174.904 40.4474 174.823 39.0293 174.676 37.9779C173.847 32.0787 169.413 27.1623 159.913 28.4966L159.979 28.5468ZM164.281 37.5915L157.753 38.5083C157.725 36.1894 158.643 34.6905 160.858 34.3795C163.073 34.0684 164.019 35.3054 164.265 37.0577C164.29 37.2329 164.314 37.4081 164.347 37.6418L164.281 37.5915Z"
          fill="#242322" />
        <path
          d="M206.618 30.9906C203.439 30.7819 202.457 30.5624 202.343 29.7447C202.22 28.8686 202.912 28.2949 204.252 28.1066C206.233 27.8283 207.064 28.6646 207.387 29.6914L216.537 28.4063C215.837 24.6927 212.132 21.163 203.098 22.4317C194.823 23.5941 191.867 27.9401 192.458 32.1456C192.909 35.358 194.81 37.8904 203.241 38.2549C206.229 38.3713 207.285 38.6994 207.424 39.6923C207.449 39.8676 207.407 39.9926 207.365 40.1176C206.813 41.6842 190.555 42.7168 190.147 42.7741C188.282 43.0361 188.085 42.0513 188.046 40.5081L187.781 31.8495L192.035 31.252L191.816 24.612L187.561 25.2095L187.367 18.7448L176.76 20.2345L176.954 26.6993L173.341 27.2068L173.561 33.8468L177.116 33.3474L177.428 43.1906C177.618 47.9288 179.335 50.8443 186.271 49.8703C188.077 49.6165 201.382 47.867 206.86 47.0976C207.268 47.0403 207.676 46.983 208.142 46.9175C216.651 45.7224 218.306 42.2737 217.592 37.1921C217.108 33.746 214.866 31.3212 206.602 30.8738L206.618 30.9906Z"
          fill="#242322" />
      </g>
      <defs>
        <clipPath id="clip0_196_8156">
          <rect width="214.397" height="58.602" fill="white" transform="translate(0.359009 30.71) rotate(-7.99501)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PureBetsIcon;