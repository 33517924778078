import { Grid, styled, Typography } from "@mui/material";
import ConversionLevelArrow from "../../../../../new_assets/ConversionLevelArrow";
import { useEffect, useRef, useState } from "react";

const svgGraphColBackground = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA2IiBoZWlnaHQ9IjM4MSIgdmlld0JveD0iMCAwIDEwNiAzODEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDBWMzgwLjM2NyIgc3Ryb2tlPSJ1cmwoI3BhaW50MF9saW5lYXJfNDkwXzE2ODApIiBzdHJva2Utb3BhY2l0eT0iMC41IiBzdHJva2Utd2lkdGg9IjAuNTk4MDYxIi8+CjxwYXRoIGQ9Ik01MyAwVjM4MC4zNjciIHN0cm9rZT0idXJsKCNwYWludDFfbGluZWFyXzQ5MF8xNjgwKSIgc3Ryb2tlLW9wYWNpdHk9IjAuNSIgc3Ryb2tlLXdpZHRoPSIwLjU5ODA2MSIvPgo8cGF0aCBkPSJNMTA1IDBWMzgwLjM2NyIgc3Ryb2tlPSJ1cmwoI3BhaW50Ml9saW5lYXJfNDkwXzE2ODApIiBzdHJva2Utb3BhY2l0eT0iMC41IiBzdHJva2Utd2lkdGg9IjAuNTk4MDYxIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfNDkwXzE2ODAiIHgxPSIxLjUiIHkxPSIwIiB4Mj0iMS41IiB5Mj0iMzgwLjM2NyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLW9wYWNpdHk9IjAiLz4KPHN0b3Agb2Zmc2V0PSIwLjMwMjA4MyIgc3RvcC1vcGFjaXR5PSIwLjE1MTY2NyIvPgo8c3RvcCBvZmZzZXQ9IjAuNDY4NzUiIHN0b3Atb3BhY2l0eT0iMC4yIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1vcGFjaXR5PSIwIi8+CjwvbGluZWFyR3JhZGllbnQ+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQxX2xpbmVhcl80OTBfMTY4MCIgeDE9IjUzLjUiIHkxPSIwIiB4Mj0iNTMuNSIgeTI9IjM4MC4zNjciIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1vcGFjaXR5PSIwIi8+CjxzdG9wIG9mZnNldD0iMC4zMDIwODMiIHN0b3Atb3BhY2l0eT0iMC4xNTE2NjciLz4KPHN0b3Agb2Zmc2V0PSIwLjQ2ODc1IiBzdG9wLW9wYWNpdHk9IjAuMiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50Ml9saW5lYXJfNDkwXzE2ODAiIHgxPSIxMDUuNSIgeTE9IjAiIHgyPSIxMDUuNSIgeTI9IjM4MC4zNjciIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1vcGFjaXR5PSIwIi8+CjxzdG9wIG9mZnNldD0iMC4zMDIwODMiIHN0b3Atb3BhY2l0eT0iMC4xNTE2NjciLz4KPHN0b3Agb2Zmc2V0PSIwLjQ2ODc1IiBzdG9wLW9wYWNpdHk9IjAuMiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=";

const GraphCol = styled(Grid)(({ theme }) => ({
  height: "100%",
  width: theme.spacing(13),
  background: `url("${svgGraphColBackground}") no-repeat`,
  backgroundSize: "100%",
  alignItems: "center",
  justifyContent: "end",
  paddingBottom: theme.spacing(4),
}));

const ColAxisDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  fontFamily: theme.typography.fontFamilyLexend,
  fontSize: theme.typography.fs12,
  lineHeight: theme.typography.lh12,
}));

const GrowingColumn = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isVisible" && prop !== "height" && prop !== "delay",
})(({ theme, isVisible, height, delay }) => ({
  background: "linear-gradient(180deg, rgba(255,144,41,1) 0%, rgba(255,255,255,1) 100%)",
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
  height: isVisible ? height : "0px", // Correctly use height here
  transition: `height 0.5s linear ${delay}s`, // Apply delay based on index
}));

const ColDescription = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  width: "fit-content",
  margin: theme.spacing("auto", "auto", 2, "auto"),
}));

const FTDAmountTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontFamily: theme.typography.fontFamilyLexend,
  lineHeight: theme.typography.lh24,
  fontWeight: theme.typography.fontWeightBold,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

const GraphItem = ({ ftdAmount, payout, height, index }) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  const delay = index * 0.5;

  return (
    <GraphCol ref={ref} container flexDirection="column">
      <Grid item mb={4} width="100%" alignItems="center">
        <ColDescription container>
          <FTDAmountTypography><ConversionLevelArrow />{ftdAmount}</FTDAmountTypography>
          <Typography variant="caption" ml="auto">FTDs</Typography>
        </ColDescription>
        <GrowingColumn container isVisible={isVisible} height={height} delay={delay}></GrowingColumn>
      </Grid>
      <ColAxisDescription>{payout} <Typography variant="caption">Payout</Typography></ColAxisDescription>
    </GraphCol>
  );
};

export default GraphItem;