import { Assets } from "../../pages/Assets";
import { BrandsReport } from "../../pages/BrandsReport";
import { DynamicVariables } from "../../pages/DynamicVariables";
import { Players } from "../../pages/Players";
import { Daily } from "../../pages/Daily";
import { Monthly } from "../../pages/Monthly";
import { Casinos } from "../../pages/Casinos";
import { Campaigns } from "../../pages/Campaigns";
import { Messages } from "../../pages/Messages";
import { Reports } from "../../pages/Reports";
import { Subaffilation } from "../../pages/Subaffilation";
import { Profile } from "../../pages/Profile";
import { Route, Routes } from "react-router-dom";
import "./styles.css";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import ErrorBoundary from "../ErrorBoundary";
import { ReportContextProvider } from "../HOC/ReportContext";

const routes = [
  { id: 1, Element: Monthly, path: "overview" },
  { id: 2, Element: DynamicVariables, path: "dynamic_variable" },
  { id: 3, Element: Daily, path: "daily" },
  { id: 4, Element: BrandsReport, path: "brands" },
  { id: 5, Element: Casinos, path: "casinos" },
  { id: 11, Element: Campaigns, path: "campaigns" },
  { id: 12, Element: Assets, path: "assets" },
  { id: 13, Element: Reports, path: "reports" },
  { id: 14, Element: Subaffilation, path: "subaffilation" },
  { id: 15, Element: Profile, path: "profile/*" },
  { id: 16, Element: Players, path: "players_report" },
  { id: 17, Element: Messages, path: "messages" },
];

const AdminRouter = () => {
  return (
    <ReportContextProvider>
      <Container fluid className="main-container">
        <Row>
          <Col lg="2" className="col-menu">
            <Navbar />
          </Col>
          <Col lg="10">
            <ErrorBoundary>
              <div className="main-content">
                <Routes>
                  {routes.map(({ id, path, Element }) => (
                    <Route key={id} path={path} element={<Element />} />
                  ))}
                </Routes>
              </div>
            </ErrorBoundary>
          </Col>
        </Row>
      </Container>
    </ReportContextProvider>
  );
};

export default AdminRouter;
