import { SvgIcon } from "@mui/material";

const DiceIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="38" height="34" viewBox="0 0 38 34" fill="none">
      <path
        d="M32.1677 18.5352L26.9464 16.6071C27.0239 18.1143 26.2007 19.5898 24.7485 20.2587L16.5442 24.0379L15.7905 26.0791C15.36 27.2449 15.956 28.5388 17.1217 28.9692L26.9716 32.6065C28.1374 33.0369 29.4313 32.4409 29.8617 31.2752L33.499 21.4253C33.9294 20.2596 33.3334 18.9656 32.1677 18.5352ZM24.255 24.8076C23.6724 24.5924 23.3742 23.9451 23.5894 23.3625C23.8047 22.7794 24.4518 22.4817 25.0344 22.6969C25.6171 22.912 25.9154 23.5588 25.7001 24.1419C25.4849 24.7246 24.8376 25.0227 24.255 24.8076ZM25.2476 15.8449L20.3958 5.3123C19.8644 4.15867 18.4982 3.65416 17.3446 4.18557L6.81153 9.03712C5.65789 9.56852 5.15339 10.9348 5.6848 12.0884L10.5363 22.6214C11.0678 23.7751 12.434 24.2796 13.5876 23.7482L24.1207 18.8966C25.2745 18.3648 25.779 16.9985 25.2476 15.8449ZM9.44788 12.9438C8.86524 12.7286 8.56708 12.0814 8.78223 11.4987C8.99754 10.9157 9.64464 10.618 10.2273 10.8331C10.8099 11.0483 11.1082 11.6951 10.8929 12.2782C10.6778 12.8608 10.0305 13.1589 9.44788 12.9438ZM12.998 20.6507C12.4153 20.4356 12.1172 19.7883 12.3323 19.2057C12.5476 18.6226 13.1947 18.3249 13.7774 18.54C14.36 18.7552 14.6583 19.402 14.443 19.9851C14.2279 20.5677 13.5806 20.8659 12.998 20.6507ZM15.0764 15.0222C14.4938 14.8071 14.1956 14.1598 14.4107 13.5772C14.6261 12.9941 15.2732 12.6964 15.8558 12.9115C16.4384 13.1267 16.7368 13.7735 16.5214 14.3566C16.3063 14.9392 15.659 15.2374 15.0764 15.0222ZM17.1548 9.3937C16.5722 9.17855 16.274 8.53129 16.4892 7.94865C16.7045 7.36557 17.3516 7.06785 17.9342 7.283C18.5169 7.49815 18.8152 8.14497 18.5999 8.72805C18.3847 9.31069 17.7374 9.60885 17.1548 9.3937ZM20.7049 17.1006C20.1223 16.8855 19.8241 16.2382 20.0393 15.6556C20.2546 15.0725 20.9017 14.7748 21.4843 14.9899C22.067 15.2051 22.3653 15.8519 22.15 16.435C21.9348 17.0176 21.2875 17.3158 20.7049 17.1006Z"
        fill="#0C1544" />
    </SvgIcon>
  );
};

export default DiceIcon;