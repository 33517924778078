import { useEffect, useState } from "react";
import Variable from "./Variable/Variable";
import { Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Reports.scss";
import PostmanExample from "../../assets/postman-example.png";
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import useAuth from "../../auth/useAuth";
import fetchData from "../../api/data";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Reports = () => {
  const [variables, setVariable] = useState([]);
  const [isVariablesLoading, setIsVariablesLoading] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  const authToken = useAuth().user.apiToken;

  useEffect(() => {
    setIsVariablesLoading(true);
    return fetchData({
      url: `/api/v3/api_reports/variables`,
      onSuccess: (response) => setVariable(response.data),
      onCallback: () => setIsVariablesLoading(false),
    });
  }, []);

  return (
    <Row>
      <h1 className={"page__title"}>API Reports - Your Token: <code>{authToken}</code></h1>
      <Variable variables={variables} loading={isVariablesLoading} />
      <div className="docs">
        <h2 className="doc__title">Guide</h2>
        <p className="doc__text">
          To use the API, you have to authenticate with a login token.
          You can find the token on this page, please keep it safe!
        </p>
        <p className="doc__text">
          Please see the examples below on how to add the authorisation header
          to apps such as Postman and various programming languages.
        </p>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Go" id="simple-tab-1" sx={{ color: value === 0 ? "#FFF !important" : null }} />
            <Tab label="cURL" id="simple-tab-2" sx={{ color: value === 1 ? "#FFF !important" : null }} />
            <Tab label="Ruby" id="simple-tab-3" sx={{ color: value === 2 ? "#FFF !important" : null }} />
            <Tab label="Postman" id="simple-tab-4" sx={{ color: value === 3 ? "#FFF !important" : null }} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <code className="code" style={{ width: "100%" }}>
            <span className="func">package</span>{" "}
            <span className="main">main</span> <br /><br />
            <span className="func">import</span> ( <br />
            &emsp;&emsp;&emsp;&#00;<span className="string">"io/ioutil"</span> <br />
            &emsp;&emsp;&emsp;&#00;<span className="string">"log"</span> <br />
            &emsp;&emsp;&emsp;&#00;<span className="string">"net/http"</span> <br />
            ) <br /><br />
            <span className="func">const</span> <span className="const">AffiliateProgram</span> = <span
            className="string">"https://vegasaffiliate.com"</span><br /><br />
            <span className="func">func</span>{" "}
            <span className="main">main</span>() &#123; <br />
            &emsp;&emsp;&emsp;&#00;req, err := <span className="package">http</span>.<span
            className="brown">NewRequest</span>(
            <span className="string">"GET"</span>,{" "}
            <span className="string">
                <span className="const"><i>AffiliateProgram</i></span> <span className="normal">+</span> "/api/v3/api_reports/variables?date_from=2021-01-01&date_to=2021-01-31"
              </span>
            , <span className="func">nil</span>) <br />
            &emsp;&emsp;&emsp;&#00;<span className="func">if</span> err !={" "}
            <span className="func">nil</span> &#123; <br />
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#00;&#00; // handle error <br />
            &emsp;&emsp;&emsp;&#00;&#125; <br />
            &emsp;&emsp;&emsp;&#00;req.Header.<span className="brown">Set</span>(
            <span className="string">"Authorization"</span>,{" "}
            <span className="string">"{authToken}"</span>) <br />
            &emsp;&emsp;&emsp;&#00;client := &<span className="package">http</span>.<span
            className="client">Client</span>&#123;&#125;<br />
            &emsp;&emsp;&emsp;&#00;resp, err := client.<span className="brown">
                Do
              </span>(req) <br />
            &emsp;&emsp;&emsp;&#00;<span className="func">if</span> err !={" "}
            <span className="func">nil</span> &#123; <br />
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#00;&#00; // handle error <br />
            &emsp;&emsp;&emsp;&#00; &#125; <br />
            &emsp;&emsp;&emsp;&#00;body, _ := <span className="package">ioutil</span>.<span
            className="brown">ReadAll</span>
            (resp.Body) <br />
            &emsp;&emsp;&emsp;&#00;log.<span className="span brown">Println</span>(
            <span className="func">string</span>(body)) <br />
            &#125;
          </code>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <code className="code">
            <span className="func">curl</span> -i -H{" "}
            <span className="string">"Accept: application/json"</span> -H{" "}
            <span className="string">"Content-Type: application/json"</span>{" "}
            -H{" "}
            <span className="string">"Authorization: {authToken}"</span>
            {" "}
            <span className="string">
              https://vegasaffiliate.com/api/v3/api_reports/variables?date_from=2021-01-01&date_to=2021-01-31
            </span>
          </code>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <code className="code">
            <span className="func">require</span>{" "}
            <span className="string">'net/http'</span> <br />
            uri = URI({" "}
            <span className="string">
                "https://vegasaffiliate.com/api/v3/api_reports/variables?date_from=2021-01-01&date_to=2021-01-31"
              </span>{" "}
            ) <br />
            req = Net::HTTP::Get.<span className="func">new</span>(uri) <br />
            req[ <span className="string">'Authorization'</span> ] =
            "{authToken}" <br />
            res = Net::HTTP.<span className="func">start</span>(uri.hostname,
            uri.port) &#123; |http| <br />
            &#00; http.request(req) <br />
            &#125; <br />
            puts res.body <br />
          </code>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <img className="postman" src={PostmanExample} alt="Postman" />
        </TabPanel>

      </div>
    </Row>
  );
};

export default Reports;
