import { useCallback, useRef } from "react";

const styles = {
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  searchTitle: {
    marginRight: "10px",
  },
};

const DEBOUNCE_TIMEOUT = 500;

const Search = ({ searchQuery }) => {
  const queryTask = useRef(null);

  const handleInput = useCallback((e) => {
    const { value } = e.target;
    queryTask.current !== null && clearTimeout(queryTask.current);
    queryTask.current = setTimeout(() => {
      searchQuery(value);
      queryTask.current = null;
    }, DEBOUNCE_TIMEOUT);
  }, []);

  return (
    <div style={styles.searchContainer}>
      <span style={styles.searchTitle}>Search:</span>
      <input
        type="text"
        name="search"
        onChange={handleInput}
      />
    </div>
  );
};

export default Search;
