import Title from "../../components/Title/Title";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Route, Routes } from "react-router";
import { UserSettings } from "../../components/UserSettings";
import { HistoricPayments } from "../../components/HistoricPayments";
import { PaymentSettings } from "../../components/PaymentSettings";
import "./Profile.scss";
import BohdanPhoto from "../../assets/bohdan.jpg";
import PasswordReset from "../../components/PasswordReset/PasswordReset";

const Profile = () => {
  return (
    <>
      <Title title="Profile info" />
      <Container>
        <Row>
          <Col lg="4">
            <div className="data__wrapper">
              <NavLink
                to="/admin/profile/user-settings"
                className="toggleSettings"
              >
                User Settings
              </NavLink>
              <NavLink
                to="/admin/profile/payment-settings"
                className="toggleSettings"
              >
                Payment Settings
              </NavLink>
              <NavLink
                to="/admin/profile/historic-payments"
                className="toggleSettings"
              >
                Historic Payments
              </NavLink>
              <NavLink
                to="/admin/profile/password-reset"
                className="toggleSettings"
              >
                Change Password
              </NavLink>
            </div>
            <div className="data__wrapper">
              <center><h4>Your affiliate Manager</h4></center>
              <hr />
              <div className="profile">
                <center><img src={BohdanPhoto} alt="Bohdan" style={{ height: "160px", width: "162" }} /></center>
                <hr />
                <div className="profile__descr">
                  <p className="profile__title">Bohdan</p>
                  <a href="mailto:affiliates@vegasaffiliate.com"><p
                    className="profile__text">affiliates@vegasaffiliate.com</p></a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="8">
            <div className="data__wrapper">
              <Routes>
                <Route path="user-settings" element={<UserSettings />} />
                <Route path="payment-settings" element={<PaymentSettings />} />
                <Route path="historic-payments" element={<HistoricPayments />} />
                <Route path="password-reset" element={<PasswordReset />} />
              </Routes>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
