import * as Yup from "yup";
import { useCallback, useEffect, useRef, useState } from "react";
import useAuth from "../../auth/useAuth";
import axios from "axios";
import { useFormik } from "formik";
import Auth, { AuthActionsContainer } from "./index";
import { Alert, Box, FormHelperText, Grid, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import StyledTextField from "../../new_components/styled-text-field";
import ReCAPTCHA from "react-google-recaptcha";
import OrangeArrowButton from "../../new_components/buttons/orange-arrow-button";

const validationSchema = Yup.object({
    password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be 8 characters or more"),
    recaptcha: Yup.string().required("ReCaptcha is required"),
});

const ResetPassword = () => {
    const [success, setSuccess] = useState(false);
    const [erCaptcha, setErCaptcha] = useState(false);
    const reCaptchaRef = useRef();
    const auth = useAuth();

    const source = axios.CancelToken.source();
    const refCode = new URL(window.location.href).searchParams.get('reset_password_token');

    const handleSubmit = async (password) => {
        try {
            await auth.updatePassword(password, refCode);
            setSuccess(true);
        } catch (error) {
            formik.setErrors({ submit: "Something went wrong" });
        }
    };

    const formik = useFormik({
        initialValues: {
            password: "",
            recaptcha: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values.password);
        },
    });

    useEffect(() => {
        return () => {
            source.cancel();
        };
    }, [source]);

    const handleCaptchaChange = useCallback((value) => {
        formik.setFieldValue("recaptcha", value);
        setErCaptcha(!value);
    }, []);

    return (
        <Auth>
            { success
                ? <Navigate to="/sign-in" />
                : <Grid container flexDirection="column" gap={1}>
                <Grid item display="flex" gap={1.5} flexDirection="column" alignItems="center">
                    <Typography variant="authTitle">Reset Password</Typography>
                </Grid>
                <Box component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Grid item display="flex" gap={1} flexDirection="column">
                        <StyledTextField
                            required
                            label="New password"
                            variant="filled"
                            fullWidth
                            type="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </Grid>
                    {formik.errors.submit && <Box item display="flex" justifyContent="center" marginTop={2}>
                        <Alert severity="error">{formik.errors.submit}</Alert>
                    </Box>}
                    <AuthActionsContainer>
                        <div
                            className="form-group"
                            style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
                        >
                            <ReCAPTCHA
                                ref={reCaptchaRef}
                                sitekey="6Lfk6tQpAAAAANeQHyiiY7FOZj7Xf-ZosDzq2eAL"
                                onChange={handleCaptchaChange}
                            />
                            {formik.touched.recaptcha && formik.errors.recaptcha && (
                                <FormHelperText error={formik.touched.recaptcha && Boolean(formik.errors.recaptcha)}>
                                    {formik.errors.recaptcha}
                                </FormHelperText>
                            )}
                            {erCaptcha && <FormHelperText error={erCaptcha}>ReCaptcha error</FormHelperText>}
                        </div>
                        <OrangeArrowButton type="submit" alignSelf="end" style={{ marginLeft: "20px" }}>
                            Update Password
                        </OrangeArrowButton>
                    </AuthActionsContainer>
                </Box>
            </Grid>
            }
        </Auth>
    );
};

export default ResetPassword;