export const typography = {
  fontFamily: "\"Poppins\", sans-serif",
  fontFamilyLexend: "\"Lexend\", sans-serif",
  fontWeightRegular: 400,
  fontWeightBold: 600,
  lh24: "24px",
  lh16: "16px",
  lh12: "12px",
  fs12: "12px",

  h1: {
    fontFamily: "\"Lexend\", sans-serif",
    fontSize: "56px",
    fontWeight: 600,
    lineHeight: "70px",
  },
  h2: {
    fontFamily: "\"Lexend\", sans-serif",
    fontSize: "48px",
    fontWeight: 600,
    lineHeight: "48px",
  },
  body1: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  body2: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  subtitle1: {
    fontFamily: "\"Lexend\", sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
  },
  subtitle2: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  caption: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "10px",
  },
  cardBackgroundText: {
    fontFamily: "\"Outfit\", sans-serif",
    fontSize: "56px",
    fontWeight: 600,
    lineHeight: "70px",
  },
  getInTouchHeading: {
    fontFamily: "\"Outfit\", sans-serif",
    fontSize: "88px",
    fontWeight: 700,
    lineHeight: "76px",
  },
  faqAccordionHeading: {
    fontFamily: "\"Lexend\", sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  authTitle: {
    fontFamily: "\"Lexend\", sans-serif",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "40px",
  },
  authSubTitle: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  authFormSubTitle: {
    fontFamily: "\"Lexend\", sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
  },
};
