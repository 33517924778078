import { Checkbox, styled } from "@mui/material";

const StyledCheckboxContainer = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.surface.border.main,
}));

const StyledCheckbox = ({ ...props }) => {
  return <StyledCheckboxContainer {...props} />;
};

export default StyledCheckbox;