import { SvgIcon } from "@mui/material";

const ChartIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} id="_x31__x2C_5" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
      <path
        d="m3 22c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
      <path
        d="m13 14c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
      <path
        d="m21 6c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
      <path
        d="m4.95 18.19c-.22 0-.438-.096-.586-.281-.259-.324-.206-.795.117-1.054l6.1-4.88c.323-.257.795-.207 1.055.117.259.324.206.795-.117 1.054l-6.1 4.88c-.139.11-.304.164-.469.164z" />
      <path
        d="m14.77 9.98c-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061l4.46-4.459c.293-.293.768-.293 1.061 0s.293.768 0 1.061l-4.461 4.459c-.147.147-.338.22-.53.22z" />
      <path
        d="m4 11c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
      <path
        d="m21 16c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
      <path
        d="m12 24c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-4.5c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
      <path
        d="m10.691 19.62c-.252 0-.498-.126-.64-.357l-5.381-8.74c-.216-.353-.107-.815.246-1.032.355-.218.815-.107 1.032.246l5.381 8.74c.217.353.107.814-.245 1.032-.123.075-.259.111-.393.111z" />
      <path
        d="m13.87 20.09c-.206 0-.412-.085-.561-.251-.275-.31-.247-.784.062-1.059l5.26-4.68c.307-.274.781-.249 1.059.062.275.31.247.784-.062 1.059l-5.26 4.68c-.142.126-.32.189-.498.189z" />
    </SvgIcon>
  );
};

export default ChartIcon;