import { Grid, styled } from "@mui/material";

export const DataRowContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "column" && prop !== "columnReverse",
})(({ theme, column, columnReverse }) => ({
  maxWidth: theme.spacing(172),
  margin: theme.spacing(15.5, "auto", 0),
  padding: theme.spacing(9, 0, 7.75),
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(10),
    flexDirection: column && "column" || columnReverse && "column-reverse",
    ...(column || columnReverse ? {
      gap: theme.spacing(3),
    } : {}),
  },
  "@media(max-width: 1155px)": {
    justifyContent: "center",
  },
}));