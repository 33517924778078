import HttpClient from '../api/api';
import User from './user';
import { RegisterOutcome } from './JWTContext';

export interface LoginResponse {
  accessToken: string;
  user: User;
}

export type DealType = 'CPA' | 'REV_SHARE' | 'HYBRID';

export interface RegisterRequestAffiliate {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  skype: string;
  website: string;
  password: string;
  confirmPassword: string;
  estimatedMonthlyFtdsRange: string;
  proposedDealType: DealType;
  message: string;
  referralCode: string;
  trafficSourcesMethodList: string[];
  trafficSourcesGeoList: string[];
}

export interface RegisterRequest {
  affiliate: RegisterRequestAffiliate;
  gRecaptchaResponse: string;
}

class AuthApi extends HttpClient {
  public constructor() {
    super();
  }

  async login({ email, password }: { email: string, password: string }): Promise<LoginResponse> {
    const data = {
      username: email,
      password,
    };
    return this.instance.post('/api/login', data, { headers: { username: email, password } })
      .then((response: any) => {
        if (response.accessToken) {
          return response;
        }
        throw new Error('Please check your email and password');
      })
      .catch((err) => {
        console.error('[Auth Api]: ', err);
        throw new Error('Error in AuthApi');
      });
  }

  async forgotPassword(email: string): Promise<void> {
    return this.instance.post(
        `/api/v3/user/forgot-password?email=${email}`,
    );
  }

  async updatePassword(newPassword: string, refCode: string): Promise<any> {
    return this.instance.post(
        `/api/v3/user/update-password`, {
          password: newPassword,
          resetPasswordToken: refCode,
        }
    );
  }

  async register(request: RegisterRequest): Promise<RegisterOutcome> {
    return this.instance.post('/api/register', request );
  }

  async me(): Promise<User> {
    return this.doGet('/api/auth/user-info')
      .then((data: any) => data)
      .catch((err) => {
        console.error('[Auth Api]: ', err);
        throw new Error('Error in AuthApi');
      });
  }
}

export const authApi = new AuthApi();
