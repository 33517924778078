import { Chip, styled } from "@mui/material";

export const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  background: theme.palette.background.medium,
  "& span": {
    ...theme.typography.subtitle2,
    lineHeight: theme.typography.lh16,
    fontFamily: theme.typography.fontFamilyLexend,
  },
}));