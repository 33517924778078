import {Card, CardContent, Link, Typography} from "@mui/material";
import React from "react";

const SingleReview = ({ singleReviewLink, singleReviewLinkText, reviewText, reviewAuthor }) => {
    return <Card sx={{ minWidth: 275 }}>
        <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                {reviewText}
            </Typography>
            <Typography variant="h5" component="div">
                {reviewAuthor}
            </Typography>

            <Link href={singleReviewLink} target="_blank"
                  rel="noopener noreferrer">{singleReviewLinkText}</Link>
        </CardContent>
    </Card>
}

export default SingleReview;