import { List, ListItem, styled } from "@mui/material";
import { Link } from "react-router-dom";


const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: 0,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: "max-content",
  padding: 0,
  color: theme.palette.text.primary,
  "&:visited": {
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(2, 0),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.surface.border.main,
    width: "100%",
    justifyContent: "center",
  },
}));

const Menu = () => {
  return (
    <StyledList disablePadding>
      <StyledListItem color="inherit" component={Link} to="/#features">Features</StyledListItem>
      <StyledListItem color="inherit" component={Link} to="/#benefits">Benefits</StyledListItem>
      <StyledListItem color="inherit" component={Link} to="/#our-brand">Our Brand</StyledListItem>
      <StyledListItem color="inherit" component={Link} to="/#about">About</StyledListItem>
      <StyledListItem color="inherit" component={Link} to="/#contact">Contacts</StyledListItem>
      <StyledListItem color="inherit" component={Link} to="/faqs">FAQ`s</StyledListItem>
    </StyledList>
  );
};

export default Menu;