import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import SliderCards from "./slider-cards";

import SlideImage from "../../../../../new_assets/images/slide-image.png";
import StatArrIcon from "../../../../../new_assets/StatArrIcon";
import DiceIcon from "../../../../../new_assets/DiceIcon";
import StatColumnIcon from "../../../../../new_assets/StatColumnIcon";
import PictureIcon from "../../../../../new_assets/PictureIcon";
import DescIcon from "../../../../../new_assets/DescIcon";
import MountainGraphIcon from "../../../../../new_assets/MountainGraphIcon";
import PayoutIcon from "../../../../../new_assets/PayoutIcon";
import SliderItem from "./slider-item";
import { useState } from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(121.75),
  height: "auto",
  aspectRatio: 1.83,
  position: "relative",
}));

const StyledSlider = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderWidth: 4,
  borderStyle: "solid",
  borderColor: theme.palette.text.primary,
  borderRadius: theme.spacing(3.4125),
  background: theme.palette.background.white,
  overflow: "hidden",
  position: "relative",
  zIndex: 1,
}));

const AnimatedSliderItem = styled(SliderItem, {
  shouldForwardProp: (prop) => prop !== "animate",
})(({ theme, animate }) => ({
  transition: "transform 1s, opacity 0.5s",
  transform: animate ? "translateY(-100%)" : "translateY(0%)",
  opacity: animate ? 0 : 1,
}));

const slides = [
  {
    id: 1,
    background: SlideImage,
    cards: [
      {
        ico: <StatArrIcon sx={{ transform: "rotate(18deg)" }} />,
        label: "High Conversion Rate",
      },
      {
        ico: <DiceIcon sx={{ transform: "rotate(-20deg)" }} />,
        label: "Popular Casino Brands",
      },
      {
        ico: <StatColumnIcon sx={{ transform: "rotate(-14deg)" }} />,
        label: "Earn Up To 50% Revenue Share",
      },
    ],
  },
  {
    id: 2,
    background: "#EBE8DF",
    cards: [
      {
        ico: <PictureIcon sx={{ transform: "rotate(18deg)" }} />,
        label: "High Converting Creatives",
      },
      {
        ico: <DescIcon sx={{ transform: "rotate(-20deg)" }} />,
        label: "Cutting Edge Reporting",
      },
      {
        ico: <MountainGraphIcon sx={{ transform: "rotate(-14deg)" }} />,
        label: "Instant Analytics Across Multiple Cost Metrics",
      },
    ],
  },
  {
    id: 3,
    background: "#F7F4EF",
    cards: [
      {
        ico: <PayoutIcon sx={{ transform: "rotate(18deg)" }} />,
        label: "Fast Payouts",
      },
      {
        ico: null,
        label: "No Fuss",
      },
      {
        ico: null,
        label: "High end Industry Driven Brands",
      },
    ],
  },
];

const HeaderSlider = () => { // slider interval => 10 seconds
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [animate, setAnimate] = useState(false);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setAnimate(true); // Start animation
  //     setTimeout(() => {
  //       setCurrentSlideIndex(prevIndex => (prevIndex + 1) % slides.length);
  //       setAnimate(false); // Reset animation
  //     }, 1000); // After 1 second of animation
  //   }, 10000);
  //
  //   return () => clearInterval(interval);
  // }, []);


  return (
    <StyledBox>
      <StyledSlider>
        <AnimatedSliderItem background={slides[currentSlideIndex].background} animate={animate} />
      </StyledSlider>
      {!isMobile && <SliderCards cards={slides[currentSlideIndex].cards} animate={animate} />}
    </StyledBox>
  );
};

export default HeaderSlider;