import { SvgIcon } from "@mui/material";

const ProfileIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} id="_x31__x2C_5" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"
             xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="m21.25 23h-18.5c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v16.5c0 1.517-1.233 2.75-2.75 2.75zm-18.5-20.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-16.5c0-.689-.561-1.25-1.25-1.25z" />
      </g>
      <g>
        <path d="m23.25 6h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
      </g>
      <g>
        <path
          d="m12 16.5c-1.378 0-2.5-1.122-2.5-2.5s1.122-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5zm0-3.5c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z" />
      </g>
      <g>
        <path
          d="m12.777 20h-1.554c-.559 0-1.053-.396-1.175-.942l-.153-.679c-.221-.105-.434-.229-.638-.37l-.66.208c-.545.168-1.138-.063-1.414-.553l-.772-1.334c-.279-.498-.185-1.106.222-1.489l.518-.475c-.009-.121-.014-.243-.014-.366s.005-.245.014-.366l-.511-.469c-.414-.389-.507-.998-.234-1.485l.782-1.352c.271-.482.864-.715 1.406-.546l.663.209c.205-.141.417-.265.638-.37l.153-.68c.124-.553.607-.941 1.175-.941h1.554c.568 0 1.051.388 1.175.942l.153.679c.221.105.434.229.638.37l.66-.208c.543-.169 1.138.063 1.414.553l.772 1.333c.279.498.185 1.106-.222 1.489l-.518.475c.009.122.014.244.014.366s-.005.245-.014.366l.511.469c.002.002.004.004.006.006.407.383.501.991.228 1.479l-.782 1.352c-.271.481-.867.711-1.406.546l-.663-.209c-.205.141-.417.265-.638.37l-.153.68c-.122.546-.617.942-1.175.942zm-1.317-1.5h1.08l.187-.826c.056-.248.233-.451.472-.539.329-.121.64-.302.925-.537.195-.162.459-.213.703-.137l.807.254.541-.935-.618-.567c-.183-.168-.271-.417-.235-.662.027-.18.042-.366.042-.553s-.016-.373-.042-.553c-.036-.246.052-.494.235-.662l.618-.567-.542-.935-.806.254c-.242.077-.506.025-.703-.137-.285-.235-.597-.416-.925-.537-.238-.088-.416-.291-.472-.539l-.187-.822h-1.08l-.187.826c-.056.248-.233.451-.472.539-.329.121-.64.302-.925.537-.196.162-.46.213-.703.137l-.807-.254-.541.935.618.567c.183.168.271.417.235.662-.026.178-.041.364-.041.551s.016.373.042.553c.036.246-.052.494-.235.662l-.618.567.542.935.806-.254c.243-.076.506-.025.703.137.285.235.597.416.925.537.238.088.416.291.472.539zm4.054-1.579c-.001.002-.002.004-.004.007zm-7.032-.007.003.006c-.001-.002-.002-.004-.003-.006zm7.033-5.834c.001.002.002.004.003.006zm-7.025-.008-.004.007c.002-.003.003-.005.004-.007z" />
      </g>
    </SvgIcon>

  );
};

export default ProfileIcon;