import "./PrivacyPolicy.scss";
import ScrollButton from "../../components/ScrollButton/ScrollButton";

const PrivacyPolicy = () => {
  return (
    <div id="container">
      <section id="terms-top" className="privacy-policy">
        <div className="innerWrap">
          <h2>Vegas Affiliate Privacy Policy</h2>
          <h6 style={{ color: "white", textAlign: "center" }}>Last updated: <strong>April 2021</strong></h6>
        </div>
      </section>

      <section id="main" className="content privacy-policy__container">
        <div className="innerWrap terms-wrap privacy-policy__box">
          <p>This privacy statement demonstrates our commitment to your privacy and security and forms part of the
            Vegas Affiliate Network General Terms & Conditions.</p>
          <p>The policy contains information about how we use, process and store your data, as well as marketing
            communications and your legal rights.</p>
          <p>We respect the privacy of our visitors and are committed to preserving your online safety by preserving
            your privacy at any time you visit or communicate with our site.</p>
          <p>This Privacy Policy is updated from time to time; therefore, it should be reviewed occasionally. This
            Policy is not intended to override the terms of any contract you have with us, nor rights you might have
            under data protection laws.</p>
          <hr />
          <h4>Who are we?</h4>
          <br />
          <p>Vegas Affiliate Network is a site operated by Bonism Limited.</p>
          <p>As the owner of Vegas Affiliate Network, Bonism Limited is principally responsible for looking after your
            personal data as both a Data Controller and a Data Processor.</p>
          <p>This Privacy Policy concerns Bonism Limited’s use of data for Vegas Affiliate Network only and does not
            incorporate data and privacy policies of other Bonism Limited-owned properties.</p>
          <p>For the remainder of this document the following terminology will apply:</p>
          <ul>
            <li>Bonism Limited – Data Controller/Processor (“Bonism Limited”, “TL“, us”, “we”, “brand owner”)</li>
            <li>Vegas Affiliate Network – This particular Bonism Limited property (“Vegas Affiliate Network”, “the site”,
              “the website”)
            </li>
            <li>Visitors and customers on our websites (“You”, “visitors”, “members”, “affiliates”)</li>
            <li>Promoted brands and third-parties (“Advertising partners”)</li>
            <li>Services offered by Vegas Affiliate Network (“Services”, “experience”)</li>
            <li>This document (“Privacy Policy”, “this policy”)</li>
            <li>General Data Protection Regulation (“GDPR”)</li>
            <li>Privacy & Electronic Communications Regulations (“PECR”)</li>
            <li>Information Commissioner’s Office (“ICO”)</li>
          </ul>
          <hr />
          <h4>Contents</h4>
          <br />
          <ol>
            <li>Your use of this website</li>
            <li>How we collect data</li>
            <li>What personal data do we collect and process?</li>
            <li>How and why we process your data</li>
            <li>How and why we share your data</li>
            <li>Non-personal information</li>
            <li>Marketing communications</li>
            <li>International transfers</li>
            <li>How long do we keep your data?</li>
            <li>Data security & storage</li>
            <li>Cookies</li>
            <li>Your rights</li>
            <li>Minors</li>
            <li>Changes to our Privacy Policy</li>
            <li>How to contact us</li>
          </ol>

          <hr />

          <h4>1. Your use of this website</h4>
          <br />
          <p>If you don’t want us to use or process your Personal Information in the ways we outline above, you should
            stop using our sites, products and services and contact us to tell us what you do and do not consent to.
            If you want to withdraw your consent to use or process your data in a certain way, your access to our sites,
            products and services may be affected.
            By using our site and interacting with our marketing communications, you are acknowledging that you have
            read the terms of this Privacy Policy and that you’re happy for us to use your Personal Information for the
            reasons set out above.</p>

          <hr />

          <h4>2. How we collect data</h4>
          <br />
          <p>Data can be collected and processed during the operations of our website. Below is a list of the current
            ways in which we may currently collect and store your data for Vegas Affiliate Network in accordance with
            GDPR and PECR.</p>
          <ul>
            <li>You register on our website</li>
            <li>You subscribe to our website mailing list</li>
            <li>You are a member at another site in the Bonism Limited group network</li>
            <li>You are a member at the website of one of our advertising partners (only where partner’s privacy policy
              permits sharing of data for affiliate marketing purposes)
            </li>
            <li>You subscribe to the mailing list of another site in the network</li>
            <li>You communicate directly with Vegas Affiliate Network through a support query</li>
          </ul>

          <hr />

          <h4>3. What data do we collect and process?</h4>
          <br />
          <p>We may process the following personal data about you:</p>
          <ul>
            <li>Full name</li>
            <li>Date of birth</li>
            <li>Email address</li>
            <li>Username</li>
            <li>Password</li>
            <li>IP address</li>
            <li>Website usage</li>
            <li>Partner site usage (selected partners)</li>
            <li>Business address</li>
            <li>Payment information - our services require financial transactions, thus we require your payment
              information such as bank account number and sort code.
            </li>
            <li>Website usage includes sign up date, last login date, marketing campaign interactions, partner link
              clicks, viewed pages.
            </li>
            <li>Partner site usage includes clicks, registrations, deposits, wagers, wins, losses and net revenue
              totals.
            </li>
            <li>Player specific data is handled by individual privacy policies which can be found on individual casino
              sites.
            </li>
          </ul>

          <hr />

          <h4>4. How and why we process your data</h4>
          <br />
          <p>We process personal information as necessary by law and for certain legitimate business purposes in a way
            that is proportionate and respects your privacy, as outlined here:</p>
          <ul>
            <li>Send promotional emails related to our own and third-party products and services (see sections 7 & 8)
            </li>
            <li>To personalise and improve your experience as an affiliate member of the Vegas Affiliate Network</li>
            <li>Analyse information in our systems and databases to provide a better and more relevant experience</li>
            <li>Providing you with the services described on the site</li>
            <li>Verifying your identity when you sign up to Vegas Affiliate Network</li>
            <li>Updating you with operational news about the site (changes, service disruptions, security updates)</li>
            <li>Monitoring activity on our site and those of our partners to identify potential fraudulent activity and
              to ensure compliance with the user terms that apply
            </li>
            <li>Managing our relationship with you, e.g. by responding to your comments or queries submitted to us, or
              asking for your feedback or whether you want to participate in a survey;
            </li>
            <li>Improving our products and services</li>
            <li>To provide players with a responsible gaming environment As is our duty of care for players that come to
              us from affiliates.
            </li>
            <li>Providing general administrative and performance functions and activities</li>
            <li>Meet or exercise any of our legal obligations or legal rights</li>
          </ul>
          <p>You have the right to object to this processing if you wish and can find more details of how to do this
            further down the page.</p>
          <hr />

          <h4>5. How and why we share your data</h4>
          <br />
          <p>We occasionally work with third parties to help manage and deliver our services, which may require access
            to your personal data. Below is a list of the third parties in question and the reasons your data may be
            shared.</p>

          <ul>
            <li>Our mailing application provider, DotDigital Limited, based in London Bridge, England. We work with
              DotDigital to send you marketing and support emails.
            </li>
            <li>Service providers or data processors that handle your personal data on our instructions, for example
              cloud services.
            </li>
            <li>Other sites in the Bonism Limited group including, but not limited to, OnlineBingo(.co.uk), for customer
              analysis, insights and cross-brand promotions.
            </li>
            <li>Our advertising partners for marketing campaign analysis and performance reviews. This helps us to show
              you only the content that interests you most.
            </li>
            <li>Our white label software providers and license holders for CRM, customer support and to fulfil legal
              obligations.
            </li>
            <li>Our analytics service providers (such as Google, PushCrew and Facebook), who process personal data for
              their own purposes as Data Controllers.
            </li>
            <li>If we are under a duty to disclose to comply with a legal obligation or protect our interests or
              security.
            </li>
            <li>A purchaser or prospective purchaser of all or part of our assets or our business, and their
              professional advisers, in connection with the purchase.
            </li>
            <li>Regulators and government authorities in connection with our compliance procedures and obligations.</li>
          </ul>

          <hr />

          <h4>6. Non-personal information</h4>
          <br />
          <p>Non-personal Information is non-identifiable information that cannot be used to identify or contact you
            when considered alone. This stops us from knowing the identity of the user from which the non-personal
            information was collected.</p>
          <p>We use non-personal information for reasons including advertising, service improvements, detecting and
            preventing fraudulent behaviour, statistical analysis and web analytics.</p>
          <h6>Types of Non-personal Information we collect about you</h6>
          <p>Technical information about the software and hardware you use to visit our site in order to provide you
            with a better experience when you visit. This can include:</p>
          <ul>
            <li>Device manufacturer & model</li>
            <li>Type of browser</li>
            <li>Operating system</li>
            <li>Language preferences</li>
            <li>Network provider</li>
          </ul>
          <p>Anonymous gameplay information for non-logged in users to monitor marketing campaign performance and to
            detect fraudulent activity affecting Vegas Affiliate Network or one of our operator partners.</p>
          <p>Analytics information that we collect about your use of our services including pages viewed, time spent on
            site, clicks and other related activity. This helps us to troubleshoot common errors, review the user
            experience and provide better insights for our operator partners.</p>
          <p> We also use third-party analytics software, such as Google Analytics, to collect anonymous user data on
            the website. Our privacy policy does not apply to these third-party services and you should always refer to
            their own policies directly.</p>
          <p>Anonymous information is collected by our services in a way that is not personally identifiable. Our use
            and disclosure of aggregated or de-identified information is not subject to any restrictions under this
            Privacy Policy, and we may disclose it to others without limitation and for any purpose, such as for
            advertising or marketing purposes.</p>
          <p>Please note that if we combine personal information with non-personal information or anonymous information,
            the combined information will be treated as personal information for as long as it remains combined.</p>

          <hr />

          <h4>7. Marketing communications</h4>
          <br />
          <p>We will use your personal data, such as your email address and telephone number, to send you marketing
            communications relevant to gambling services, promotions and products.</p>
          <p>In most cases, we will use our legitimate interests as the lawful basis for processing your data for direct
            marketing cases, unless we are legally required to seek your explicit consent.</p>
          <p>Our marketing communications may include the promotion of third-party advertising partners for which this
            Privacy Policy does not directly apply.</p>
          <p>We will only send you communications that we believe are of most interest and relevance to you, based on
            the personal information we hold. You have the right to decline the receipt of marketing promotions from us
            at any time; simply contact affiliates@vegasaffiliate.com.</p>
          <h6>Consent to third party promotions</h6>
          <p>As outlined above, our marketing communications will often promote the products and services from our
            third-party advertising partners, where their privacy policies allow for such promotion, see section 4.</p>
          <p>In these situations, our communication will retain Vegas Affiliate Network branding, but the content will
            focus on the specific offer or service from the third-party(ies) in question.</p>
          <p>Where possible, we screen against third-party suppression lists to ensure you only receive promotions from
            brands which you have not withdrawn consent.</p>
          <p>Please note that Vegas Affiliate Network is not responsible for the privacy practices or the content of
            these third party sites. We advise our users to check individual terms and conditions/privacy policies where
            appropriate.</p>
          <h6>Behaviour monitoring & profiling</h6>
          <p>We use automated decision tools provided by our email service provider (DotDigital) to segment our database
            according to certain actions or inaction of our users.</p>
          <p>This can include:</p>
          <ul>
            <li>Whether or not you opened a particular email</li>
            <li> Whether or not you clicked a particular email</li>
            <li>Your tendency to interact with emails of a particular theme</li>
            <li>This segmentation is handled automatically without human intervention. You can withdraw your consent to
              be profiled in this way by contacting us via the methods outlined in section 8 of this privacy policy.
            </li>
          </ul>

          <hr />

          <h4>8. International transfers</h4>
          <br />
          <p>Personal Information collected by us may be stored and processed in any country where our advertising
            partners, subsidiaries, suppliers or agents are located. We may also need to transfer data (including
            Personal Information) to other Bonism Limited companies for the supply of our services.</p>
          <p>If this happens, all information will be managed according to and subject to all relevant national,
            European and international data protection and privacy laws and regulations, including the UK Data
            Protection Act and the EU General Data Protection Regulations. You hereby consent to transfer of your
            Personal Information to countries outside the European Union.</p>

          <hr />

          <h4>9. How long do we keep your data?</h4>
          <br />
          <p>We will retain your personal data for as long as reasonably necessary for the purposes listed in Section 4
            of this Policy.</p>
          <p>In some circumstances, we keep your personal data during a certain period to meet for example legal, tax or
            accounting requirements.</p>
          <p>We maintain a data retention policy for personal data in our care. Where your personal data is no longer
            required we will ensure it is either securely deleted or made anonymous.</p>

          <hr />

          <h4>10. Data security</h4>
          <br />
          <p>All of the information that you provide and we collect about you is stored on secure servers, but you
            should understand that transmission of online data is not always completely secure.</p>
          <p>We cannot guarantee full protection and security data, only that we take all reasonable action to protect
            information sent to us electronically. Transmission of any data by you is at your own risk.</p>
          <p>Where applicable you may be given access to sections of our site that require a username and password. You
            are responsible for the safety, appropriateness and confidentiality of any usernames or passwords created at
            Vegas Affiliate Network.</p>

          <hr />

          <h4>11. Cookies</h4>
          <br />
          <p>There are instances where we may use cookies to gather information regarding our services in a mathematical
            collection for our website and advertising.</p>
          <p>Any information collected will not have any identifying data. It is statistical data about our visitors and
            how they have used our site. No personal details will be shared that could identify you.</p>
          <p>We may assemble information about your common Internet use with a cookie file. When used, the cookies are
            downloaded to your computer automatically. The cookie is stored on the hard drive, with transferred
            information. The data sought by the cookie helps us improve our website and any service offered to you.</p>
          <p>Your browser has the ability to decline cookies via your browser’s options. Note: if you do decline the
            download of cookies, some aspects of our site may not work or allow you access.</p>
          <p>Our advertisers may download cookies to which we have no control. If used, these cookies are downloaded by
            clicking on an advertisement found on our website.</p>
          <p>For full details of how we set and use cookies, please view our Cookie Policy.</p>

          <hr />

          <h4>12. Your rights</h4>
          <br />
          <p>As a UK or EU citizen, you have a number of rights under the General Data Protection Regulation, which came
            into place on 25th May 2018. Some of these apply at any time, but some may only apply in certain
            circumstances.</p>
          <h6>Access to personal data</h6>
          <p>You have a right to request a copy of the personal information that we hold about you.</p>
          <h6>Correction of personal data</h6>
          <p>You can contact us to check and correct any personal data that we are processing about you which you think
            is incorrect.</p>
          <h6>Right to withdraw consent</h6>
          <p>Where we have relied upon your consent to process your personal data, you have the right to withdraw that
            consent.</p>
          <h6>Right to erase</h6>
          <p>You can ask us to erase your personal data where there is no legal reason or legitimate interest in keeping
            it.</p>
          <h6>Right to data portability</h6>
          <p>You can request a copy of the information you have provided us and the right to ask that your personal data
            is transferred by us directly to another organisation. For security reasons, we will only do this if it is
            technically possible.</p>
          <h6>Right to restrict processing of personal data</h6>
          <p>In some circumstances you have the right to request that we suspend our processing of your personal
            data.</p>
          <h6>Right to object to processing of personal data</h6>
          <p>You have the right to object to our use of your personal data which is processed on the basis of our
            legitimate interests. We will still be allowed to keep your personal data to meet our legitimate interests
            and regulatory obligations.</p>
          <h6>Rights relating to automated decision making and profiling</h6>
          <p>You have the right not to be subject to a decision which is based solely on an automated process. We do not
            currently make any decisions about any customer in this way, but if this changes, we will write to tell
            you.</p>

          <hr />

          <h4>13. Minors</h4>
          <br />
          <p>The services offered by Vegas Affiliate Network are not designed or directed to persons under the age of 18
            or persons under the age of legal consent with respect to the use of the Services of any jurisdiction in
            which the individual resides, whichever is higher (“Legally of Age”). If you are not Legally of Age, you
            should not download or use the Services nor provide any Personal Information to us.</p>
          <p>We reserve the right to access and verify any personal information collected from you. In the event that we
            become aware that a user who is not Legally of Age has shared any information, we may discard such
            information. If you have any reason to believe that a minor has shared any information with us, please
            contact us at affiliates@vegasaffiliate.com.</p>

          <hr />

          <h4>14. Changes to our privacy policy</h4>
          <br />
          <p>From time to time we’ll have to update our Privacy Policy. Where we make any changes which could impact
            your rights, we’ll do our best to make sure we let you know.</p>
          <p>This will be done either by contacting you using the details you’ve given us, or via our sites. At this
            point, you’ll be required to confirm that you’ve read and accepted the changes before you can continue to
            use our sites, products or services.</p>
          <p>If, for any reason, we don’t or can’t confirm your acceptance of the changes, but you continue to use our
            sites, products or services, we’re allowed to believe that you have accepted the changes.</p>
          <p>All activity undertaken prior to the notification and acceptance of the change will be governed by the
            terms in place at the time that activity was undertaken.</p>
          <p>If you have any concerns or questions about how we use your data, you should stop using our sites, products
            and services and contact us for more information.</p>
          <p>This Privacy Policy is effective from 28th April 2021 and overrides all previous versions.</p>

          <hr />

          <h4>15. Contacting Us</h4>
          <br />
          <p>Please feel free to contact us with queries, requests, or comments you may have about our Privacy Policy or
            dealings with Vegas Affiliate Network. You can contact us via: <a
              href="mailto:affiliates@vegasaffiliate.com">affiliates@vegasaffiliate.com</a> or our online support form.</p>
          <p>The Brand Owner details are as follow:</p>
          <p>Name: <strong>Bonism Limited</strong></p>
          <p>Last updated: <strong>June 2024</strong></p>


        </div>
        <ScrollButton />
      </section>
    </div>
  );
};

export default PrivacyPolicy;
