import { Radio, styled } from "@mui/material";

const StyledRadioContainer = styled(Radio)(({ theme }) => ({
  color: theme.palette.surface.border.main,
}));

const StyledRadio = ({ ...props }) => {
  return <StyledRadioContainer {...props} />;
};

export default StyledRadio;