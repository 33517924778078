import { styled } from "@mui/material";
import GraphItem from "./graph-item";
import { PreStyledMainCard } from "../../../../../new_components/cards-data-container";

const GraphContainer = styled(PreStyledMainCard)(({ theme }) => ({
  padding: theme.spacing(0, 10.5),
  display: "flex",
  gap: theme.spacing(6.5),
}));

const itemArr = [
  {
    ftdAmount: "1-35",
    payout: "25%",
    height: "138px",
  },
  {
    ftdAmount: "36-70",
    payout: "35%",
    height: "192px",
  },
  {
    ftdAmount: "71-100",
    payout: "40%",
    height: "222px",
  },
  {
    ftdAmount: "100+",
    payout: "50%",
    height: "274px",
  },
];

const Graph = () => {
  return (
    <GraphContainer item>
      {itemArr.map((item, i) => <GraphItem key={item.ftdAmount} {...item} index={i} />)}
    </GraphContainer>
  );
};

export default Graph;