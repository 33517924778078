import { Box, Grid, styled, Typography } from "@mui/material";

const SliderCard = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "animate",
})(({ theme, animate }) => ({
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.surface.border.white,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(5, 2.5),
  background: theme.palette.background.darkBeige,
  position: "absolute",
  boxShadow: theme.shadows[1],
  transition: "transform 1s, opacity 0.5s",
  opacity: animate ? 0 : 1,
  transform: animate ? "translateY(-100%)" : "translateY(0%)",
}));

const FirstCard = styled(SliderCard)(({ theme }) => ({
  transform: "translate(-48%, -61%) rotate(-19deg)",
  top: 0,
}));

const SecondCard = styled(SliderCard)(({ theme }) => ({
  transform: "translate(75%, -50%) rotate(20deg)",
  right: 0,
  top: "50%",
  zIndex: 2,
  "@media(max-width: 1345px)": {
    transform: "translate(40%, -50%) rotate(20deg)",
  },
}));

const ThirdCard = styled(SliderCard)(({ theme }) => ({
  transform: "translate(-35%, 25%) rotate(13deg)",
  bottom: 0,
  zIndex: 2,
}));

const SliderCardContent = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  borderRadius: theme.spacing(1.5),
  background: theme.palette.background.white,
  padding: theme.spacing(1.25, 1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const SliderCards = ({ cards, animate }) => {
  return (
    <Box component="div">
      <FirstCard item animate={animate}>
        <SliderCardContent>
          {cards[0].ico}
          {cards[0].label}
        </SliderCardContent>
      </FirstCard>
      <SecondCard item animate={animate}>
        <SliderCardContent>
          {cards[1].ico}
          {cards[1].label}
        </SliderCardContent>
      </SecondCard>
      <ThirdCard item animate={animate}>
        <SliderCardContent>
          {cards[2].ico}
          {cards[2].label}
        </SliderCardContent>
      </ThirdCard>
    </Box>
  );
};

export default SliderCards;