import { SvgIcon } from "@mui/material";

const LogoComponent = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="181" height="32" style={{ width: 181, height: 32 }}
         viewBox="0 0 181 32" fill="none">
      <path
        d="M36.8699 10.3316C39.1088 10.3316 40.7669 11.1565 41.8443 12.8062C42.9385 14.4391 43.1069 16.375 42.3493 18.614H32.7792C32.8971 19.2368 33.1748 19.7166 33.6125 20.0533C34.0502 20.3731 34.5889 20.533 35.2286 20.533C36.5079 20.533 37.5601 20.0448 38.3849 19.0685L41.5413 20.5835C40.8679 21.5599 39.9421 22.3679 38.7637 23.0076C37.6021 23.6473 36.348 23.9672 35.0013 23.9672C33.1496 23.9672 31.6429 23.4537 30.4814 22.4268C29.3367 21.3831 28.7643 19.9859 28.7643 18.2352C28.7643 16.0804 29.5555 14.2287 31.1379 12.68C32.7371 11.1144 34.6478 10.3316 36.8699 10.3316ZM37.9809 14.296C37.5601 13.9425 37.055 13.7658 36.4659 13.7658C35.8767 13.7658 35.2959 13.9257 34.7235 14.2455C34.168 14.5654 33.7135 15.0115 33.36 15.5838H38.7637C38.6627 15.062 38.4018 14.6327 37.9809 14.296Z"
        fill="#0C1544" />
      <path
        d="M54.5603 14.2203L55.3431 10.5841H59.3833L56.8077 22.7046C56.4036 24.6405 55.503 26.1724 54.1058 27.3003C52.7254 28.445 51.0504 29.0173 49.0809 29.0173C47.6837 29.0173 46.4464 28.6722 45.369 27.9821C44.3084 27.3087 43.593 26.4586 43.2227 25.4317L46.884 23.6641C47.3722 24.7752 48.1718 25.3307 49.2829 25.3307C50.1414 25.3307 50.8989 25.0361 51.5555 24.4469C52.2288 23.8746 52.6665 23.1255 52.8685 22.1996L53.323 20.0785C52.7002 21.1054 51.9342 21.9302 51.0252 22.5531C50.133 23.1591 49.1987 23.4621 48.2223 23.4621C46.8925 23.4621 45.8403 22.9992 45.066 22.0733C44.2916 21.1475 43.9044 19.8007 43.9044 18.0332C43.9044 15.9458 44.5778 14.1445 45.9245 12.6295C47.2712 11.0976 48.8536 10.3316 50.6717 10.3316C51.6144 10.3316 52.4561 10.6683 53.1968 11.3417C53.9375 11.9982 54.392 12.9577 54.5603 14.2203ZM50.4192 19.7755C51.4124 19.7755 52.2541 19.4136 52.9443 18.6897C53.6513 17.949 54.0048 17.0989 54.0048 16.1394C54.0048 15.4997 53.786 14.9862 53.3483 14.5991C52.9274 14.2119 52.3719 14.0183 51.6817 14.0183C50.6885 14.0183 49.8384 14.3886 49.1314 15.1293C48.4412 15.8532 48.0961 16.6949 48.0961 17.6544C48.0961 18.2941 48.3065 18.8075 48.7274 19.1947C49.165 19.5819 49.729 19.7755 50.4192 19.7755Z"
        fill="#0C1544" />
      <path
        d="M67.2562 10.3316C69.0238 10.3316 70.3789 10.7946 71.3216 11.7204C72.2812 12.6463 72.5589 14.0688 72.1549 15.9879L70.5136 23.7146H66.4734L67.0795 20.7855C66.0526 22.9066 64.5628 23.9672 62.6101 23.9672C61.6505 23.9672 60.8341 23.6473 60.1607 23.0076C59.4874 22.3511 59.1507 21.5094 59.1507 20.4825C59.1507 19.1526 59.6473 18.0837 60.6405 17.2757C61.6337 16.4676 63.0141 15.9794 64.7816 15.8111L67.13 15.5838C67.8707 15.5165 68.2831 15.2472 68.3673 14.7758C68.4346 14.4391 68.3252 14.1614 68.039 13.9425C67.7528 13.7237 67.3657 13.6143 66.8775 13.6143C66.2546 13.6143 65.7328 13.7574 65.3119 14.0435C64.9079 14.3297 64.6554 14.7337 64.5544 15.2556L60.7162 14.397C61.0192 13.2692 61.7599 12.3096 62.9383 11.5184C64.1167 10.7272 65.556 10.3316 67.2562 10.3316ZM64.3271 20.8108C65.0341 20.8108 65.7243 20.5583 66.3977 20.0533C67.0711 19.5482 67.4751 18.9843 67.6097 18.3614L67.635 18.2352C67.3488 18.3699 67.0206 18.454 66.6502 18.4877L64.6554 18.715C63.6959 18.8328 63.2161 19.2368 63.2161 19.927C63.2161 20.5162 63.5864 20.8108 64.3271 20.8108Z"
        fill="#0C1544" />
      <path
        d="M73.0361 20.129L76.9753 19.2705C77.1268 20.2973 77.7328 20.8108 78.7934 20.8108C79.4499 20.8108 79.9633 20.7098 80.3337 20.5078C80.7208 20.2889 80.9144 20.0028 80.9144 19.6492C80.9144 19.3294 80.6872 19.0937 80.2327 18.9422C79.7781 18.7739 79.231 18.6392 78.5913 18.5382C77.9517 18.4204 77.312 18.2604 76.6723 18.0584C76.0326 17.8564 75.4855 17.4861 75.031 16.9474C74.5764 16.4087 74.3492 15.7017 74.3492 14.8263C74.3492 13.6143 74.8963 12.5621 75.9905 11.6699C77.1015 10.7777 78.4819 10.3316 80.1317 10.3316C81.6804 10.3316 82.9598 10.6852 83.9698 11.3922C84.9798 12.0824 85.5859 13.0082 85.7879 14.1698L81.8487 15.0283C81.6972 14.0014 81.0828 13.488 80.0054 13.488C79.5509 13.488 79.1721 13.589 78.8691 13.791C78.5661 13.9762 78.4146 14.2287 78.4146 14.5485C78.4146 14.8852 78.6419 15.1377 79.0964 15.3061C79.5509 15.4744 80.098 15.6175 80.7377 15.7353C81.3774 15.8532 82.0171 16.0215 82.6567 16.2404C83.2964 16.4424 83.8435 16.8127 84.2981 17.3514C84.7526 17.8901 84.9798 18.5971 84.9798 19.4725C84.9798 20.8024 84.3486 21.8882 83.086 22.7299C81.8235 23.5547 80.3926 23.9672 78.7934 23.9672C77.1941 23.9672 75.8811 23.6221 74.8542 22.9319C73.8273 22.2417 73.2213 21.3074 73.0361 20.129Z"
        fill="#0C1544" />
      <path
        d="M97.9529 10.3316C99.7205 10.3316 101.076 10.7946 102.018 11.7204C102.978 12.6463 103.256 14.0688 102.852 15.9879L101.21 23.7146H97.1701L97.7761 20.7855C96.7493 22.9066 95.2595 23.9672 93.3067 23.9672C92.3472 23.9672 91.5307 23.6473 90.8574 23.0076C90.184 22.3511 89.8474 21.5094 89.8474 20.4825C89.8474 19.1526 90.344 18.0837 91.3372 17.2757C92.3304 16.4676 93.7107 15.9794 95.4783 15.8111L97.8267 15.5838C98.5673 15.5165 98.9798 15.2472 99.0639 14.7758C99.1313 14.4391 99.0219 14.1614 98.7357 13.9425C98.4495 13.7237 98.0623 13.6143 97.5741 13.6143C96.9513 13.6143 96.4294 13.7574 96.0086 14.0435C95.6046 14.3297 95.3521 14.7337 95.2511 15.2556L91.4129 14.397C91.7159 13.2692 92.4566 12.3096 93.635 11.5184C94.8134 10.7272 96.2527 10.3316 97.9529 10.3316ZM95.0238 20.8108C95.7308 20.8108 96.421 20.5583 97.0944 20.0533C97.7677 19.5482 98.1717 18.9843 98.3064 18.3614L98.3317 18.2352C98.0455 18.3699 97.7172 18.454 97.3469 18.4877L95.3521 18.715C94.3925 18.8328 93.9128 19.2368 93.9128 19.927C93.9128 20.5162 94.2831 20.8108 95.0238 20.8108Z"
        fill="#0C1544" />
      <path
        d="M125.934 5.3572C126.406 4.88585 126.97 4.65018 127.626 4.65018C128.283 4.65018 128.847 4.88585 129.318 5.3572C129.789 5.82855 130.025 6.39249 130.025 7.04902C130.025 7.68871 129.789 8.25264 129.318 8.74083C128.847 9.21218 128.283 9.44786 127.626 9.44786C126.97 9.44786 126.406 9.21218 125.934 8.74083C125.463 8.25264 125.227 7.68871 125.227 7.04902C125.227 6.39249 125.463 5.82855 125.934 5.3572ZM120.303 10.5841H128.889L126.086 23.7146H122.046L124.091 14.1193H119.546L117.5 23.7146H113.46L115.506 14.1193H110.961L108.915 23.7146H104.875L106.92 14.1193H104.395L105.153 10.5841H107.678L107.728 10.3316C108.116 8.4294 108.823 7.07427 109.849 6.26624C110.876 5.44137 112.4 5.02894 114.42 5.02894H116.061L115.304 8.56407H113.662C113.09 8.56407 112.669 8.67349 112.4 8.89234C112.13 9.11118 111.937 9.50678 111.819 10.0791L111.718 10.5841H116.263L116.314 10.3316C116.701 8.4294 117.408 7.07427 118.435 6.26624C119.462 5.44137 120.985 5.02894 123.005 5.02894H124.647L123.889 8.56407H122.248C121.675 8.56407 121.254 8.67349 120.985 8.89234C120.716 9.11118 120.522 9.50678 120.404 10.0791L120.303 10.5841Z"
        fill="#0C1544" />
      <path d="M132.651 23.7146H128.611L132.575 5.02894H136.615L132.651 23.7146Z" fill="#0C1544" />
      <path
        d="M138.352 7.04902C138.352 6.39249 138.588 5.82855 139.059 5.3572C139.53 4.88585 140.094 4.65018 140.751 4.65018C141.407 4.65018 141.971 4.88585 142.443 5.3572C142.914 5.82855 143.15 6.39249 143.15 7.04902C143.15 7.68871 142.914 8.25264 142.443 8.74083C141.971 9.21218 141.407 9.44786 140.751 9.44786C140.094 9.44786 139.53 9.21218 139.059 8.74083C138.588 8.25264 138.352 7.68871 138.352 7.04902ZM139.21 23.7146H135.17L137.973 10.5841H142.013L139.21 23.7146Z"
        fill="#0C1544" />
      <path
        d="M149.86 10.3316C151.628 10.3316 152.983 10.7946 153.926 11.7204C154.885 12.6463 155.163 14.0688 154.759 15.9879L153.118 23.7146H149.078L149.684 20.7855C148.657 22.9066 147.167 23.9672 145.214 23.9672C144.255 23.9672 143.438 23.6473 142.765 23.0076C142.092 22.3511 141.755 21.5094 141.755 20.4825C141.755 19.1526 142.251 18.0837 143.245 17.2757C144.238 16.4676 145.618 15.9794 147.386 15.8111L149.734 15.5838C150.475 15.5165 150.887 15.2472 150.971 14.7758C151.039 14.4391 150.929 14.1614 150.643 13.9425C150.357 13.7237 149.97 13.6143 149.482 13.6143C148.859 13.6143 148.337 13.7574 147.916 14.0435C147.512 14.3297 147.26 14.7337 147.159 15.2556L143.32 14.397C143.623 13.2692 144.364 12.3096 145.542 11.5184C146.721 10.7272 148.16 10.3316 149.86 10.3316ZM146.931 20.8108C147.638 20.8108 148.328 20.5583 149.002 20.0533C149.675 19.5482 150.079 18.9843 150.214 18.3614L150.239 18.2352C149.953 18.3699 149.625 18.454 149.254 18.4877L147.26 18.715C146.3 18.8328 145.82 19.2368 145.82 19.927C145.82 20.5162 146.191 20.8108 146.931 20.8108Z"
        fill="#0C1544" />
      <path
        d="M158.705 14.1193H156.179L156.937 10.5841H159.462L160.22 7.04902L164.512 5.78647L163.502 10.5841H166.659L165.901 14.1193H162.745L161.785 18.6645C161.583 19.6745 161.979 20.1795 162.972 20.1795H164.613L163.856 23.7146H162.492C160.674 23.7146 159.353 23.218 158.528 22.2248C157.703 21.2316 157.476 19.8765 157.846 18.1594L158.705 14.1193Z"
        fill="#0C1544" />
      <path
        d="M174.291 10.3316C176.53 10.3316 178.188 11.1565 179.266 12.8062C180.36 14.4391 180.528 16.375 179.771 18.614H170.201C170.318 19.2368 170.596 19.7166 171.034 20.0533C171.472 20.3731 172.01 20.533 172.65 20.533C173.929 20.533 174.981 20.0448 175.806 19.0685L178.963 20.5835C178.289 21.5599 177.363 22.3679 176.185 23.0076C175.024 23.6473 173.769 23.9672 172.423 23.9672C170.571 23.9672 169.064 23.4537 167.903 22.4268C166.758 21.3831 166.186 19.9859 166.186 18.2352C166.186 16.0804 166.977 14.2287 168.559 12.68C170.159 11.1144 172.069 10.3316 174.291 10.3316ZM175.402 14.296C174.981 13.9425 174.476 13.7658 173.887 13.7658C173.298 13.7658 172.717 13.9257 172.145 14.2455C171.589 14.5654 171.135 15.0115 170.781 15.5838H176.185C176.084 15.062 175.823 14.6327 175.402 14.296Z"
        fill="#0C1544" />
      <path
        d="M8.39452 0.796875H0L5.12998 26.1388C5.35453 27.2413 5.80362 28.163 6.45998 28.921L11.5813 17.5422L8.39452 0.796875Z"
        fill="#0C1544" />
      <path
        d="M23.033 0.796875L8.99892 30.6696C9.91437 31.04 10.8644 31.2296 11.8921 31.2296C13.2566 31.2296 14.6039 30.8764 15.9339 30.1528C17.2553 29.4465 18.2916 28.3611 19.0257 26.914L32.0493 0.796875H23.033Z"
        fill="#FF9029" />
    </SvgIcon>
  );
};

export default LogoComponent;