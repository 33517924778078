import Auth, { AuthActionsContainer, Star } from "./index";
import { Alert, Box, FormGroup, FormHelperText, Grid, Link, RadioGroup, styled, Typography } from "@mui/material";
import OrangeArrowButton from "../../new_components/buttons/orange-arrow-button";
import StyledCheckbox from "../../new_components/checkbox";
import StyledRadio from "../../new_components/radio";
import CustomFormControlLabel from "../../new_components/form-control-label";
import ReCAPTCHA from "react-google-recaptcha";
import { useCallback, useMemo, useRef, useState } from "react";
import useAuth from "../../auth/useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthTextField from "../../new_components/styled-text-field/auth-text-field";
import { HashLink } from "react-router-hash-link";
import { Multiselect } from "multiselect-react-dropdown";
import { countries } from "countries-list";


const GEOsMultiSelectStyles = {
  "searchBox": {
    padding: "16px 12px",
    background: "#FFFFFF",
    borderRadius: "8px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0)",
  },
  "chips": {
    background: "#FF9029",
  },
};

const FieldsRowContainer = styled(Grid)(({ theme }) => ({
  "@media(max-width: 510px)": {
    flexDirection: "column",
  },
}));

const SignupSchema = Yup.object({
  firstName: Yup
    .string()
    .min(3, "First Name must be at least 3 characters")
    .required("First Name is required"),
  lastName: Yup
    .string()
    .min(2, "Last Name must be at least 2 characters"),
  email: Yup
    .string()
    .email("Email is invalid")
    .required("Email is required"),
  password: Yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  companyName: Yup
    .string()
    .min(3, "Company name must be at least 3 characters")
    .required("Company name is required"),
  phoneNumber: Yup
    .string()
    .min(10, "Phone number must be at least 10 characters")
    .required("Phone number is required"),
  skype: Yup
    .string()
    .min(3, "Skype must be at least 3 characters")
    .required("Skype is required"),
  website: Yup
    .string(),
  trafficSourcesMethodList: Yup
    .array()
    .min(1, "Traffic sources method must be at least 1 selection")
    .required("Traffic sources method is required"),
  trafficSourcesGeoList: Yup
    .array()
    .min(1, "Traffic sources geo must be at least 1 selection")
    .required("Traffic sources geo is required"),
  estimatedMonthlyFtdsRange: Yup
    .string()
    .min(1, "Estimated monthly FTD should be selected")
    .required("Estimated monthly FTD is required"),
  proposedDealType: Yup
    .string()
    .min(1, "Proposed Deal Type should be selected")
    .required("Proposed Deal Type is required"),
  recaptcha: Yup.string().required("ReCaptcha is required"),
  terms: Yup.boolean().isTrue("Required"),
});

const SignUp = () => {
  const auth = useAuth();
  const [outcome, setOutcome] = useState(null);
  const [failed, setFailed] = useState(false);
  const [erCaptcha, setErCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const reCaptchaRef = useRef();
  let referralCode;
  const cookies = document.cookie;
  const refCode = cookies.split(";")[0].split("=");
  if (refCode[0] === "referral_code") {
    referralCode = refCode[1];
  }
  const countryList = useMemo(() => Object.keys(countries).map((code, index) => {
    return {
      name: countries[code].name,
      code: code,
      id: index,
    };
  }), []);

  const submitForm = (values) => {
    console.log("Submitting form with values:", values);

    const { recaptcha, ...rest } = values;

    setOutcome(null);
    setFailed(false);
    setErCaptcha(false);
    if (captcha) {
      auth
        .register({
          affiliate: {
            ...rest,
            referralCode: referralCode,
          },
          gRecaptchaResponse: captcha,
        })
        .then((response) => {
          setOutcome(response["outcome"]);
        }).catch((err) => {
        setOutcome(err.response?.data?.outcome);
        setFailed(true);
        reCaptchaRef.current.reset();
      });
    } else {
      setErCaptcha(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
      companyName: "",
      phoneNumber: "",
      skype: "",
      website: "",
      trafficSourcesMethodList: [],
      trafficSourcesGeoList: [],
      estimatedMonthlyFtdsRange: "ZERO_TO_FIFTY",
      proposedDealType: "REV_SHARE",
      message: "",
      recaptcha: "",
      terms: false,
    },
    validationSchema: SignupSchema,
    onSubmit: submitForm,
  });

  const handleCaptchaChange = useCallback((value) => {
    setCaptcha(value);
    formik.setFieldValue("recaptcha", value);
  }, [formik.setFieldValue]);

  const handleCheckboxArrayChange = (fieldName) => (event) => {
    const { value, checked } = event.target;
    const currentArray = formik.values[fieldName];

    if (checked) {
      const newArray = currentArray.includes(value) ? currentArray : [...currentArray, value];
      formik.setFieldValue(fieldName, newArray);
    } else {
      const newArray = currentArray.filter(item => item !== value);
      formik.setFieldValue(fieldName, newArray);
    }
  };

  const onGEOSelect = useCallback((selectedList, selectedItem) => {
    const currentArray = formik.values["trafficSourcesGeoList"];

    const newArray = currentArray.includes(selectedItem.code) ? currentArray : [...currentArray, selectedItem.code];
    formik.setFieldValue("trafficSourcesGeoList", newArray);
  }, [formik]);

  const onGEORemove = useCallback((selectedList, selectedItem) => {
    const currentArray = formik.values["trafficSourcesGeoList"];

    const newArray = currentArray.filter(item => item !== selectedItem.code);
    formik.setFieldValue("trafficSourcesGeoList", newArray);
  }, [formik]);

  return (
    <Auth>
      <Grid container flexDirection="column" gap={1}>
        <Grid item display="flex" gap={1.5} flexDirection="column" alignItems="center">
          <Typography variant="authTitle">Sign Up</Typography>
          <Typography variant="authSubTitle" display="flex" gap={1}>Already have an account?
            <Link sx={{ textDecoration: "none" }} variant="faqAccordionHeading" href={"/sign-in"}>Sign In</Link>
          </Typography>
        </Grid>
        {failed && (
          <>
            {outcome === "EMAIL_ALREADY_USED" && (
              <Alert severity="warning">
                This email address is already used
              </Alert>
            )}
            {outcome === "FORM_ERROR" && (
              <Alert severity="error">
                Please check if the form is filled out correctly
              </Alert>
            )}
            {(outcome !== "FORM_ERROR" && outcome !== "EMAIL_ALREADY_USED") && (
              <Alert severity="error">
                There was an error, pleas try again later or <HashLink to="contact">contact us</HashLink>
              </Alert>
            )}
          </>
        )}
        {outcome === "SUCCESS" ? (
          <Alert severity="success">
            Registration Successful - Please check your email for further information
          </Alert>
        ) : (
          <Box
            component="form"
            noValidate
            autoComplete="off"
            display="flex"
            flexDirection="column"
            // onSubmit={formik.handleSubmit}
          >
            <Grid item display="flex" gap={1} flexDirection="column">
              <FieldsRowContainer container wrap="nowrap" gap={1}>
                <AuthTextField name="firstName" label="First Name"
                               value={formik.values.firstName}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                               helperText={formik.touched.firstName && formik.errors.firstName} />
                <AuthTextField name="lastName" label="Last Name" notRequired
                               value={formik.values.lastName}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur} />
              </FieldsRowContainer>
              <Grid container>
                <AuthTextField name="email" label="Email"
                               value={formik.values.email}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.email && Boolean(formik.errors.email)}
                               helperText={formik.touched.email && formik.errors.email} />
              </Grid>
              <FieldsRowContainer container wrap="nowrap" gap={1}>
                <AuthTextField name="password" label="Password" type="password" value={formik.values.password}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.password && Boolean(formik.errors.password)}
                               helperText={formik.touched.password && formik.errors.password} />
                <AuthTextField name="confirmPassword" label="Confirm Password" type="password"
                               value={formik.values.confirmPassword}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                               helperText={formik.touched.confirmPassword && formik.errors.confirmPassword} />
              </FieldsRowContainer>
              <Grid container>
                <AuthTextField name="companyName" label="Company Name"
                               value={formik.values.companyName}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                               helperText={formik.touched.companyName && formik.errors.companyName} />
              </Grid>
              <Grid container>
                <AuthTextField name="phoneNumber" label="Phone number" type="phone"
                               value={formik.values.phoneNumber}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                               helperText={formik.touched.phoneNumber && formik.errors.phoneNumber} />
              </Grid>
              <Grid container>
                <AuthTextField name="skype" label="Skype"
                               value={formik.values.skype}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.skype && Boolean(formik.errors.skype)}
                               helperText={formik.touched.skype && formik.errors.skype} />
              </Grid>
              <Grid container>
                <AuthTextField name="website" label="Website" notRequired
                               value={formik.values.website}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur} />
              </Grid>
              <Grid container flexDirection="column">
                <Typography variant="authFormSubTitle">Traffic Sources (Method)
                  <Star component="span">*</Star>
                </Typography>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  <CustomFormControlLabel
                    control={
                      <StyledCheckbox
                        size="small"
                        value="SEO"
                        checked={formik.values.trafficSourcesMethodList.includes("SEO")}
                        onChange={handleCheckboxArrayChange("trafficSourcesMethodList")}
                      />
                    }
                    label="SEO"
                  />
                  <CustomFormControlLabel
                    control={
                      <StyledCheckbox
                        size="small"
                        value="PPC"
                        checked={formik.values.trafficSourcesMethodList.includes("PPC")}
                        onChange={handleCheckboxArrayChange("trafficSourcesMethodList")}
                      />
                    }
                    label="PPC"
                  />
                  <CustomFormControlLabel
                    control={
                      <StyledCheckbox
                        size="small"
                        value="Email"
                        checked={formik.values.trafficSourcesMethodList.includes("Email")}
                        onChange={handleCheckboxArrayChange("trafficSourcesMethodList")}
                      />
                    }
                    label="Email"
                  />
                  <CustomFormControlLabel
                    control={
                      <StyledCheckbox
                        size="small"
                        value="Other"
                        checked={formik.values.trafficSourcesMethodList.includes("Other")}
                        onChange={handleCheckboxArrayChange("trafficSourcesMethodList")}
                      />
                    }
                    label="Other"
                  />
                </FormGroup>
                {formik.touched.trafficSourcesMethodList && formik.errors.trafficSourcesMethodList && (
                  <FormHelperText
                    error={formik.touched.trafficSourcesMethodList && Boolean(formik.errors.trafficSourcesMethodList)}>{formik.errors.trafficSourcesMethodList}</FormHelperText>
                )}
              </Grid>
              <Grid container flexDirection="column">
                <Typography variant="authFormSubTitle" mb={1}>Traffic Sources (GEOs)
                  <Star component="span">*</Star>
                </Typography>
                <Box sx={{
                  ".multiSelectContainer li:hover": {
                    background: "#FF9029",
                  },
                }}>
                  <Multiselect options={countryList} displayValue="name" onSelect={onGEOSelect} onRemove={onGEORemove}
                               avoidHighlightFirstOption
                               style={GEOsMultiSelectStyles} />
                </Box>

                {formik.touched.trafficSourcesGeoList && formik.errors.trafficSourcesGeoList && (
                  <FormHelperText
                    error={formik.touched.trafficSourcesGeoList && Boolean(formik.errors.trafficSourcesGeoList)}>{formik.errors.trafficSourcesGeoList}</FormHelperText>
                )}
              </Grid>
              <Grid container flexDirection="column">
                <Typography variant="authFormSubTitle">Estimated monthly FTDs
                  <Star component="span">*</Star>
                </Typography>
                <FormGroup>
                  <RadioGroup
                    row
                    name="estimatedMonthlyFtdsRange"
                    value={formik.values.estimatedMonthlyFtdsRange}
                    onChange={formik.handleChange}
                  >
                    <CustomFormControlLabel
                      control={<StyledRadio size="small" value="ZERO_TO_FIFTY" />}
                      label="0-50" />
                    <CustomFormControlLabel
                      control={<StyledRadio size="small"
                                            value="FIFTY_ONE_TO_ONE_HUNDRED" />}
                      label="51-100" />
                    <CustomFormControlLabel
                      control={<StyledRadio size="small" value="ONE_HUNDRED_AND_ONE_TO_TWO_HUNDRED" />}
                      label="101-200" />
                    <CustomFormControlLabel
                      control={<StyledRadio size="small" value="TWO_HUNDRED_AND_ONE_TO_THREE_HUNDRED" />}
                      label="201-300" />
                    <CustomFormControlLabel
                      control={<StyledRadio size="small" value="THREE_HUNDRED_PLUS" />}
                      label="300+" />
                  </RadioGroup>
                  {formik.touched.estimatedMonthlyFtdsRange && formik.errors.estimatedMonthlyFtdsRange && (
                    <FormHelperText
                      error={formik.touched.estimatedMonthlyFtdsRange && Boolean(formik.errors.estimatedMonthlyFtdsRange)}>{formik.errors.estimatedMonthlyFtdsRange}</FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid container flexDirection="column">
                <Typography variant="authFormSubTitle">Proposed Deal Type
                  <Star component="span">*</Star>
                </Typography>
                <FormGroup>
                  <RadioGroup
                    row
                    name="proposedDealType"
                    value={formik.values.proposedDealType}
                    onChange={formik.handleChange}
                  >
                    <CustomFormControlLabel control={<StyledRadio size="small" value="REV_SHARE" />} label="Revshare" />
                    <CustomFormControlLabel control={<StyledRadio size="small" value="CPA" />} label="CPA" />
                    <CustomFormControlLabel control={<StyledRadio size="small" value="HYBRID" />} label="Hybrid" />
                  </RadioGroup>
                  {formik.touched.proposedDealType && formik.errors.proposedDealType && (
                    <FormHelperText
                      error={formik.touched.proposedDealType && Boolean(formik.errors.proposedDealType)}>{formik.errors.proposedDealType}</FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid container>
                <AuthTextField
                  label="Message"
                  multiline
                  rows={4}
                  notRequired
                />
              </Grid>
            </Grid>
            <AuthActionsContainer item>
              <div
                className="form-group"
                style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
              >
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey="6Lfk6tQpAAAAANeQHyiiY7FOZj7Xf-ZosDzq2eAL"
                  onChange={handleCaptchaChange}
                />
                {formik.touched.recaptcha && formik.errors.recaptcha && (
                  <FormHelperText
                    error={formik.touched.recaptcha && Boolean(formik.errors.recaptcha)}>{formik.errors.recaptcha}</FormHelperText>
                )}
                {erCaptcha && <FormHelperText
                  error={erCaptcha}>ReCaptcha error</FormHelperText>}
              </div>
              <OrangeArrowButton type="button" onClick={() => formik.submitForm()} alignSelf="end">Sign
                Up</OrangeArrowButton>
            </AuthActionsContainer>
            <Grid container display="flex" flexDirection="column">
              <CustomFormControlLabel
                control={
                  <StyledCheckbox
                    size="small"
                    name="terms"
                    checked={formik.values.terms}
                    onChange={formik.handleChange}
                  />
                }
                label={<Link target="_blank" href="./terms">I accept the terms and conditions</Link>}
                width="fit-content"
              />
              {formik.errors.terms && (
                <FormHelperText
                  error={!!formik.errors.terms} style={{marginTop:"-9px"}}>{formik.errors.terms}</FormHelperText>
              )}
            </Grid>
          </Box>
        )}
      </Grid>
    </Auth>
  );
};

export default SignUp;