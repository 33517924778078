export default [
  {
    singleReviewLinkText:"BonusManiac",
    singleReviewLink:"https:\/\/bonusmaniac.com\/",
    reviewText:"We are happy to share our positive experience with Vegas Affiliate! It is a fantastic collaboration platform offering attractive promotions and impeccable service. This is a great choice for you. BonusManiac is highly recommended.",
    reviewAuthor:"Cheryl"
  },
  {
    singleReviewLinkText:"Gamblescope",
    singleReviewLink:"https:\/\/gamblescope.com\/",
    reviewText:"As a leading casino aggregator, Gamblescope.com has had the pleasure of collaborating with PureBets.com, and we cannot recommend them highly enough. We have been thoroughly impressed with both their product offerings and the technical support provided to players. ",
    reviewAuthor:"Antonio"
  },
  {
    singleReviewLinkText:"CryptoGamblers",
    singleReviewLink:"https:\/\/cryptogamblers.co\/",
    reviewText:"Collaborating with Vegas Affiliate has been an extraordinary experience. Their professionalism, combined with their relentless drive for superior outcomes, makes them a truly distinguished partner. We eagerly recommend their services.",
    reviewAuthor:"Alkis"
  },
  {
    singleReviewLinkText:"Sloterman",
    singleReviewLink:"https:\/\/sloterman-canada.com\/",
    reviewText:"Vegas Affiliates has been an incredible experience for us. We appreciate their approach, which helped us achieve new success levels. Their timely payments and excellent management skills set them apart from other affiliate programs. With their help, we could identify and overcome our weaknesses and turn them into strengths. We highly recommend Vegas Affiliates to anyone looking for a reliable and effective affiliate program.",
    reviewAuthor:"Michael "
  },
  {
    singleReviewLinkText:"Sloterman",
    singleReviewLink:"https:\/\/sloterman.de\/",
    reviewText:"Vegas Affiliates has been instrumental in helping us achieve our goals. With their management tools, we got the long-awaited results we sought. Their qualified managers are always available to process requests and work out strategies to solve any problems we may encounter. Their effective service package has helped us make our site the best. We highly recommend Vegas Affiliates to anyone looking for a reliable and effective affiliate program.",
    reviewAuthor:"Mykhail"
  },
  {
    singleReviewLinkText:"Sloterman",
    singleReviewLink:"https:\/\/sloterman-au.com\/",
    reviewText:"Vegas Affiliates has been an amazing partner for us. Their creativity and originality are what set them apart from other affiliate programs. This reliable company offers customers excellent support and quick competent answers at any time. Thanks to the individual approach, they can analyze every detail that directly affects the success of our website.",
    reviewAuthor:"Mykhail"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/onlinecasinorank.org\/",
    reviewText:"Vegas Affiliate has partnered with Online Casino Rank to bring players the ultimate guide for safe and rewarding casino experiences. The platform\u2019s expert reviews and reliable insights are unmatched!",
    reviewAuthor:"Ergys"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/livecasinorank.com\/",
    reviewText:"Vegas Affiliate proudly recommends Live Casino Rank for its in-depth reviews and top-notch guidance in the live casino scene. The site\u2019s commitment to quality keeps players coming back.",
    reviewAuthor:"Filip"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/mobilecasinorank.com\/",
    reviewText:"Vegas Affiliate is thrilled to collaborate with Mobile Casino Rank. This site is the go-to source for anyone seeking the best mobile casino experience, offering comprehensive and expert reviews.",
    reviewAuthor:"Ergys"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/bettingranker.com\/",
    reviewText:"Vegas Affiliate supports Betting Ranker for its exceptional coverage of the sports betting world. Players love the valuable insights and trusted tips available on this outstanding platform.",
    reviewAuthor:"Filip"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/slotsrank.com\/",
    reviewText:"Vegas Affiliate highly recommends Slots Rank for its thorough and insightful slot game reviews. It's the perfect place for slot enthusiasts to find the best gaming options online.",
    reviewAuthor:"Ergys"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/newcasinorank.com\/",
    reviewText:"Vegas Affiliate endorses New Casino Rank as a premier source for discovering the latest and most exciting casino platforms. It\u2019s a must-visit for players looking to stay ahead of the game.",
    reviewAuthor:"Filip"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/esportranker.com\/",
    reviewText:"Vegas Affiliate proudly partners with Esport Ranker to bring players top-notch insights into the esports betting world. It\u2019s a go-to site for esports fans and bettors alike.",
    reviewAuthor:"Ergys"
  },
  {
    singleReviewLinkText:"Casinorank",
    singleReviewLink:"https:\/\/cryptocasinorank.com\/",
    reviewText:"Vegas Affiliate recommends Crypto Casino Rank for its expert guidance in the rapidly growing world of crypto casinos. It's the best place for reliable information on this emerging market.",
    reviewAuthor:"Filip"
  },
  {
    singleReviewLinkText:"CasinoJan",
    singleReviewLink:"https:\/\/casinojan.com\/",
    reviewText:"Working with Vegas Affiliates has been a positive experience since the beginning. Their team has provided steady support and fair terms. We look forward to keep working together.",
    reviewAuthor:"Mats"
  },
  {
    singleReviewLinkText:"iCasinoBalkan",
    singleReviewLink:"https:\/\/icasinobalkan.com\/",
    reviewText:"Partnering with Vegas Affiliates has truly been a game-changer for us. From day one, their team has gone above and beyond to support our affiliate efforts, offering exceptional resources, clear communication, and insightful marketing strategies that have significantly boosted our reach and revenue.",
    reviewAuthor:"Josip"
  }
]