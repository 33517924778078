import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import DataTable from "react-data-table-component";
import "./HistoricPayments.scss";
import { Circular } from "../Circular/Circular";
import fetchData from "../../api/data";

const HistoricPayments = () => {
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  const columns = [
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      grow: 3,
      style: {
        fontSize: "14px",
      },
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      grow: 2,
      style: {
        fontSize: "14px",
      },
    },
    {
      name: "Month",
      selector: (row) => row.month,
      sortable: true,
    },
    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: "/api/v3/payments/all",
      onSuccess: (response) => {
        setUserData(response.data);
      },
      onCallback: () => setLoading(false),
    });
  }, []);

  return (
    <>
      <h4 className="settings__title">All Time Payments</h4>
      {isLoading && <CircularProgress />}
      {!isLoading && userData && (
        <div className="profile__form">
          <DataTable
            title="Payments"
            columns={columns}
            data={userData}
            pagination
            paginationServer
            paginationPerPage={20}
            progressComponent={<Circular />}
          />
        </div>
      )}
    </>
  );
};

export default HistoricPayments;
