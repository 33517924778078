import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Alert, Button, Grid } from "@mui/material";

type Status = 'INITIAL' | 'SENDING' | 'SUCCESS' | 'ERROR';

const PasswordReset = () => {
  const [status, setStatus] = useState<Status>('INITIAL');
  const [message, setMessage] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

  useEffect(() => {
    if (newPassword === newPasswordRepeat || !newPasswordRepeat) {
      setStatus('INITIAL');
    } else {
      setStatus('ERROR');
      setMessage('Repeated password doesn\'t match');
    }
  }, [newPassword, newPasswordRepeat]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      setStatus('SENDING');
      axios({
        url: `/api/v3/user/change-password`,
        method: 'POST',
        data: { oldPassword, newPassword },
        headers: {
          Authorization: `Bearer ${(window?.localStorage?.getItem("accessToken"))}`,
        }})
        .then(() => {
          setStatus('SUCCESS');
        })
        .catch((error) => {
          console.log(error);
          setStatus('ERROR');
          if (error.response.data?.ERROR) {
            setMessage(error.response.data.ERROR);
          } else {
            setMessage('');
          }
        });
    },
  });

  return (
    <>
      <h4 className="settings__title">Change Password</h4>
        <div className="profile__form">
        {status === 'SUCCESS' && <Alert severity="success">Password updated</Alert>}
        {status === 'ERROR' && <Alert severity="warning">{message ?? 'Failed updating password'}</Alert>}

        <form className="signup-form" onSubmit={formik.handleSubmit}>
          <Grid
            item
            container
            xs={12}
          >
            <label htmlFor="oldPassword">Old Password</label>
            <input type="password" name="oldPassword" id="oldPassword" onChange={(e) => setOldPassword(e.target.value)} />
          </Grid>
          <Grid
            item
            container
            xs={12}
          >
            <label htmlFor="password">New Password</label>
            <input type="password" name="password" id="password" onChange={(e) => setNewPassword(e.target.value)} />
          </Grid>
          <Grid
            item
            container
            xs={12}
          >
            <label htmlFor="newPasswordRepeat">Repeat New Password</label>
            <input type="password" name="newPasswordRepeat" id="newPasswordRepeat" onChange={(e) => setNewPasswordRepeat(e.target.value)} />
          </Grid>
          <Grid
            item
            container
            xs={12}
          >
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              id={"save-button"}
              disabled={status === 'SENDING' || !newPassword || !oldPassword || (newPassword !== newPasswordRepeat)}
            >
              Save
            </Button>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default PasswordReset;
