import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import "bootstrap/dist/css/bootstrap.min.css";
import fetchData from "../../api/data";
import { toastApiError } from "../../utils/toastApiError";

const columns = [
  {
    name: "Link Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "URL",
    selector: (row) => row.url,
    sortable: true,
  },
];

const Campaigns = ({ brandsFilter }) => {
  const [loading, setLoading] = useState(false);
  const [links, setVariables] = useState([]);

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: `/api/v3/links/all?${brandsFilter}`,
      onSuccess: (response) => {
        setVariables(response.data);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [brandsFilter]);

  return (
    <Row>
      <div className="data__wrapper">
        <DataTable
          title="Your Links"
          columns={columns}
          data={links}
          progressPending={loading}
          progressComponent={<Circular />}
        />
      </div>
    </Row>
  );
};

export default connectTopLine(false, true, Campaigns);
