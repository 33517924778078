import { Link as RouterLink, useLocation } from "react-router-dom";
import { Breadcrumbs, Link, styled, Typography } from "@mui/material";

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
}));

const BreadcrumbsNav = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter(x => x);

  if (location.pathname === "/") {
    return null;
  }

  return (
    <StyledBreadcrumbs separator="/" aria-label="breadcrumb">
      <Link component={RouterLink} color="inherit" to="/">
        Home
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        value = value === "faqs" ? "FAQ's" : `${value.charAt(0).toUpperCase()}${value.slice(1, value.length)}`;

        return last ? (
          <Typography key={to} color="text.primary">{value}</Typography>
        ) : (
          <Link key={to} component={RouterLink} color="inherit" to={to}>
            {value}
          </Link>
        );
      })}
    </StyledBreadcrumbs>
  );
};
export default BreadcrumbsNav;