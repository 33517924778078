import { SvgIcon } from "@mui/material";

const StatColumnIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M17.7507 18.3283L19.501 18.7486C19.7927 18.8186 20.1544 18.597 20.2245 18.3053L21.6955 12.1794C21.7655 11.8877 21.5439 11.5259 21.2521 11.4559L19.5019 11.0356C19.2102 10.9656 18.8484 11.1872 18.7784 11.4789L17.3074 17.6048C17.2374 17.8965 17.459 18.2583 17.7507 18.3283ZM22.1263 19.379L23.8766 19.7993C24.1683 19.8693 24.5301 19.6477 24.6001 19.356L27.1218 8.85449C27.1918 8.56278 26.9702 8.20103 26.6785 8.13098L24.9282 7.7107C24.6365 7.64066 24.2748 7.86232 24.2047 8.15403L21.683 18.6555C21.613 18.9472 21.8346 19.309 22.1263 19.379ZM8.9995 16.2269L10.7497 16.6472C11.0415 16.7172 11.4032 16.4956 11.4733 16.2039L12.2438 12.9951C12.3138 12.7034 12.0922 12.3416 11.8004 12.2716L10.0502 11.8513C9.75849 11.7813 9.39673 12.0029 9.32669 12.2946L8.55617 15.5034C8.48613 15.7951 8.70779 16.1569 8.9995 16.2269ZM13.3751 17.2776L15.1254 17.6979C15.4171 17.7679 15.7788 17.5463 15.8489 17.2546L18.0203 8.21163C18.0904 7.91992 17.8687 7.55817 17.577 7.48812L15.8267 7.06784C15.535 6.9978 15.1733 7.21946 15.1032 7.51117L12.9318 16.5541C12.8617 16.8458 13.0834 17.2076 13.3751 17.2776ZM24.4888 23.0316L4.79854 18.3034L8.12575 4.44731C8.2225 4.04439 7.97452 3.63968 7.5716 3.54293L6.11306 3.19269C5.71014 3.09594 5.30542 3.34393 5.20867 3.74685L1.53122 19.0615C1.33783 19.8669 1.83414 20.6769 2.63953 20.8703L23.7883 25.9487C24.1913 26.0454 24.596 25.7974 24.6927 25.3945L25.043 23.936C25.1397 23.533 24.8917 23.1283 24.4888 23.0316Z"
        fill="#0C1544" />
    </SvgIcon>
  );
};

export default StatColumnIcon;