import { SvgIcon } from "@mui/material";

const StatArrIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <path
        d="M28.1264 17.6753L8.96359 24.2212L4.35726 10.7362C4.22331 10.3441 3.79721 10.135 3.40508 10.2689L1.98561 10.7538C1.59349 10.8877 1.38437 11.3138 1.51832 11.706L6.60952 26.6104C6.87727 27.3942 7.73006 27.8127 8.51387 27.545L29.0962 20.5143C29.4883 20.3803 29.6974 19.9542 29.5635 19.5621L29.0786 18.1426C28.9447 17.7505 28.5186 17.5414 28.1264 17.6753ZM22.3431 5.38497L17.1061 7.17386C16.1577 7.49782 16.0743 8.80677 16.9741 9.24836L18.9023 10.1946L16.7596 14.5614L12.3933 12.4185C11.6494 12.0535 10.7507 12.3605 10.3861 13.1042L8.37991 17.192C8.19737 17.5639 8.35086 18.0133 8.72281 18.1958L10.0689 18.8565C10.4409 19.039 10.8902 18.8855 11.0728 18.5136L12.4179 15.7721L16.7842 17.9149C17.528 18.28 18.4267 17.973 18.7914 17.2293L21.5952 11.5162L23.5233 12.4625C24.4231 12.9041 25.4075 12.0373 25.0835 11.0889L23.2948 5.85242C23.1613 5.46014 22.7352 5.25102 22.3431 5.38497Z"
        fill="#0C1544" />
    </SvgIcon>
  );
};

export default StatArrIcon;