import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Alert, AlertTitle, Button, CircularProgress, Container, Grid } from "@mui/material";
import fetchData from "../../api/data";

const PaymentSettings = () => {
  const [userData, setUserData] = useState({
    bankAccountName: "",
    bankAccountNumber: "",
    bankAddress: "",
    bankCity: "",
    bankCountry: "",
    bankIban: "",
    bankName: "",
    bankPostcode: "",
    bankSortCode: "",
    bankSwift: "",
  });
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isSaving, setSaving] = useState(false);
  const token = window?.localStorage?.getItem("accessToken");

  const checkStateValue = useCallback((value) => (messages.find((message) => message.field === value)
  ), [messages]);

  const customValidate = useCallback((values) => {
    const valuesArray = Object.entries(values);
    if (valuesArray.length !== 0) {
      valuesArray.map(value => {
        switch (value[0]) {
          case "bankAccountName":
            if (value[1].length > 20 || value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Bank account name must be 20 characters or less" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankAccountNumber":
            if (value[1].length > 20 || value[1].length < 4) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Bank account number must be 20 characters or less" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankAddress":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Address must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankCity":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "City must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankCountry":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Country must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankIban":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Iban must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankName":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Name must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankPostcode":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Postcode must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankSortCode":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Sort code must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          case "bankSwift":
            if (value[1].length < 3) {
              const newMessages = messages;
              if (!checkStateValue(value[0])) {
                newMessages.push({ field: value[0], text: "Swift must be 3 characters or more" });
              }
              setMessages(newMessages);
            }
            break;
          default:
            break;
        }
        return null;
      });
    }
  }, [messages, setMessages, checkStateValue]);

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: `/api/v3/payments/settings`,
      onSuccess: (resp) => {
        const {
          bankAccountName,
          bankAccountNumber,
          bankAddress,
          bankCity,
          bankCountry,
          bankIban,
          bankName,
          bankPostcode,
          bankSortCode,
          bankSwift,
        } = resp.data;
        setUserData({
          bankAccountName, bankAccountNumber, bankAddress, bankCity, bankCountry, bankIban, bankName, bankPostcode, bankSortCode, bankSwift,
        });
        formik.initialValues = {
          bankAccountName, bankAccountNumber, bankAddress, bankCity, bankCountry, bankIban, bankName, bankPostcode, bankSortCode, bankSwift,
        };
      },
      onCallback: () => setLoading(false),
    });
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      if (!Object.keys(values).length) return;

      setError(false);
      setSuccess(false);
      customValidate(values);

      if (messages.length === 0) {
        setSaving(true);
        const user = {
          ...userData,
          ...values,
        };
        axios({
          url: `/api/v3/payments/settings`,
          method: "put",
          data: {
            user,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(() => {
            setSuccess(true);
            setSaving(false);
          })
          .catch((error) => {
            console.log(error);
            setError(true);
            setSaving(false);
          });
      } else {
        setError(true);
      }
    },
  });

  return (
    <Container className={"center-block"}>
      <h4 className="settings__title">Payment Settings</h4>
      {isLoading && <CircularProgress />}
      {!isLoading ? (
        <div className="profile__form">
          {success && <Alert severity="success">Update payment information is successful</Alert>}
          <form className="signup-form" onSubmit={formik.handleSubmit}>
            {error && <Alert severity="error">Update payment information is failed</Alert>}
            {messages.length !== 0 && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {messages.map(message => (<span key={message.field} className="alert--error">{message.text}</span>))}
              </Alert>
            )}
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankAccountName">Bank account name</label>
              <input type="text" name="bankAccountName" id="bankAccountName" defaultValue={userData.bankAccountName}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankAccountNumber">Bank account number</label>
              <input type="text" name="bankAccountNumber" id="bankAccountNumber"
                     defaultValue={userData.bankAccountNumber} onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankAddress">Bank address</label>
              <input type="text" name="bankAddress" id="bankAddress" defaultValue={userData.bankAddress}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankCity">Bank city</label>
              <input type="text" name="bankCity" id="bankCity" defaultValue={userData.bankCity}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankCountry">Bank country</label>
              <input type="text" name="bankCountry" id="bankCountry" defaultValue={userData.bankCountry}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankIban">Bank IBAN</label>
              <input type="text" id="bankIban" name="bankIban" defaultValue={userData.bankIban}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankName">Bank name</label>
              <input type="text" name="bankName" id="bankName" defaultValue={userData.bankName}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankPostcode">Bank postcode</label>
              <input type="text" name="bankPostcode" id="bankPostcode" defaultValue={userData.bankPostcode}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankSortCode">Bank sort code</label>
              <input type="text" name="bankSortCode" id="bankSortCode" defaultValue={userData.bankSortCode}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <label htmlFor="bankSwift">Bank swift</label>
              <input type="text" name="bankSwift" id="bankSwift" defaultValue={userData.bankSwift}
                     onChange={formik.handleChange} />
            </Grid>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
                disabled={isSaving}
                onClick={() => {
                  setMessages([]);
                }}
              >
                Save
              </Button>
            </Grid>
          </form>
        </div>
      ) : error}
    </Container>
  );
};

export default PaymentSettings;
