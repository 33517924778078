import { Grid, styled, Typography } from "@mui/material";
import UnderlinedHeaderTextWord from "../../new_components/underlined-header-text-word";
import { StyledContainer } from "../../new_components/layouts/MainLayout";
import Spoiler from "./components/spoiler";
import GetInTouch from "../home/components/get-in-touch";

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: "clamp(2.25rem, -2.8636rem + 21.8182vw, 3rem)",
}));

const FAQs = () => {
  return (
    <StyledContainer container>
      <Grid item width="100%">
        <Grid container flexDirection="column" alignItems="center" gap={0.5}>
          <StyledHeading variant="h2">Frequently
            Asked <UnderlinedHeaderTextWord>Questions</UnderlinedHeaderTextWord></StyledHeading>
          <Typography color="text.secondary" variant="body1" sx={(theme) => ({ maxWidth: theme.spacing(60) })}
                      textAlign="center">Do you need
            some help with
            something or do you
            have questions on some
            features?</Typography>
        </Grid>
      </Grid>

      <Spoiler />

      <GetInTouch />
    </StyledContainer>
  );
};

export default FAQs;