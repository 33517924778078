import { Box, styled } from "@mui/material";

const StyledItem = styled("img")(({ theme }) => ({
  objectFit: "fill",
  width: "100%",
  height: "100%",
}));

const SliderItem = ({ background, ...rest }) => {
  const isColorString = background.includes("#");
  return (
    <Box {...rest} component="div" height="inherit" sx={{ background: isColorString ? background : undefined }}>
      {!isColorString && <StyledItem src={background} />}
    </Box>
  );
};

export default SliderItem;