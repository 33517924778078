import { styled } from "@mui/material";
import { StyledButton } from "./styled-button";

const StyledOrangeButton = styled(StyledButton)(({ theme }) => ({
  backgroundImage: theme.palette.surface.gradient.orangeBackground,
  color: theme.palette.primary.contrastText,
  "svg": {
    "rect": {
      opacity: 1,
      transition: "all 300ms linear",
    },
  },
  "&:hover": {
    "svg": {
      "rect": {
        opacity: 0,
      },
    },
  },
}));

const OrangeButton = ({ ...props }) => {
  return <StyledOrangeButton {...props} />;
};

export default OrangeButton;