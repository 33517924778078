import { SvgIcon } from "@mui/material";

const SignUpArrow = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" rx="16" fill="white" fillOpacity="0.2" />
      <path d="M19.3335 13.3334L13.3335 19.3334M19.3335 13.3334L19.3335 17.1046M19.3335 13.3334L15.5622 13.3333"
            stroke="white" strokeWidth="1.33333" />
    </SvgIcon>
  );
};

export default SignUpArrow;