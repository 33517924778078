
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import React from "react";
import {Grid, styled, Typography} from "@mui/material";
import { StyledContainer } from "../../../../new_components/layouts/MainLayout";
import GetInTouch from "../get-in-touch";
import InfiniteCarousel from 'react-leaf-carousel';
import ReviewsData from './ReviewsData'
import SingleReview from "./SingleReview";

const StyledHeading = styled(Typography)(({ theme }) => ({
    maxWidth: theme.spacing(48),
    fontSize: "clamp(2.5rem, -0.9091rem + 14.5455vw, 3rem)",
}));

const Reviews = () => {
    return (
        <StyledContainer container>
            <Grid item width="100%">
                <Grid container flexDirection="column" alignItems="center" gap={0.5} sx={{ marginBottom: 5 }}>
                    <StyledHeading variant="h2">
                        <UnderlinedHeaderTextWord>Testimonials</UnderlinedHeaderTextWord>
                    </StyledHeading>
                </Grid>

                <ReviewsCarousel />
            </Grid>
            <GetInTouch />
        </StyledContainer>
    );
}

const ReviewsCarousel = () => {
    return <InfiniteCarousel
        breakpoints={[
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
        ]}
        showSides
        sidesOpacity={.8}
        sideSize={.5}
        slidesToScroll={2}
        slidesToShow={3}
        arrows
        scrollOnDevice
        autoCycle
        cycleInterval={3500}
        pauseOnHover
    >
        {
            ReviewsData.map(data =>
                <SingleReview
                    singleReviewLink={data.singleReviewLink}
                    singleReviewLinkText={data.singleReviewLinkText}
                    reviewText={data.reviewText}
                    reviewAuthor={data.reviewAuthor}
                />
            )
        }
    </InfiniteCarousel>
}

export default Reviews;