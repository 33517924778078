import Auth, { AuthActionsContainer } from "./index";
import { Alert, Box, FormHelperText, Grid, Link, Typography } from "@mui/material";
import StyledTextField from "../../new_components/styled-text-field";
import OrangeArrowButton from "../../new_components/buttons/orange-arrow-button";
import ReCAPTCHA from "react-google-recaptcha";
import { useCallback, useRef, useState } from "react";
import useAuth from "../../auth/useAuth";
import { Navigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

const signInSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().required("Password is required"),
  recaptcha: Yup.string().required("ReCaptcha is required"),
});

const SignIn = () => {
  const auth = useAuth();
  const reCaptchaRef = useRef();
  const [erCaptcha, setErCaptcha] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      recaptcha: "",
    },
    validationSchema: signInSchema,
    onSubmit: (values) => {
      handleSubmit(values.email, values.password);
    },
  });

  const handleCaptchaChange = useCallback((value) => {
    formik.setFieldValue("recaptcha", value);
    setErCaptcha(!value);
  }, []);

  const handleSubmit = async (email, password) => {
    if (!formik.values.recaptcha) {
      setErCaptcha(true);
      return;
    }
    setErCaptcha(false);
    try {
      await auth.login(email, password);
      // Handle successful login
    } catch (error) {
      // Handle login error
      formik.setErrors({ submit: "Unrecognised Details" });
    }
  };

  if (auth.isAuthenticated) return <Navigate to="/admin/overview" />;

  return (
    <Auth>
      <Grid container flexDirection="column" gap={1}>
        <Grid item display="flex" gap={1.5} flexDirection="column" alignItems="center">
          <Typography variant="authTitle">Sign In</Typography>
          <Typography variant="authSubTitle" display="flex" gap={1}>
            Don’t have an account?
            <Link sx={{ textDecoration: "none" }} variant="faqAccordionHeading" href={"/sign-up"}>Sign Up</Link>
          </Typography>
        </Grid>
        <Box component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid item display="flex" gap={1} flexDirection="column">
            <StyledTextField
              required
              label="Email"
              variant="filled"
              fullWidth
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <StyledTextField
              required
              label="Password"
              variant="filled"
              fullWidth
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Link sx={{ textDecoration: "none" }} variant="faqAccordionHeading" href={"/forgot"}>Forgot password?</Link>
          </Grid>
          {formik.errors.submit && <Box item display="flex" justifyContent="center" marginTop={2}>
            <Alert severity="error">{formik.errors.submit}</Alert>
          </Box>}
          <AuthActionsContainer>
            <div
              className="form-group"
              style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
            >
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey="6Lfk6tQpAAAAANeQHyiiY7FOZj7Xf-ZosDzq2eAL"
                onChange={handleCaptchaChange}
              />
              {formik.touched.recaptcha && formik.errors.recaptcha && (
                <FormHelperText
                  error={formik.touched.recaptcha && Boolean(formik.errors.recaptcha)}>{formik.errors.recaptcha}</FormHelperText>
              )}
              {erCaptcha && <FormHelperText
                error={erCaptcha}>ReCaptcha error</FormHelperText>}
            </div>
            <OrangeArrowButton type="submit" alignSelf="end">Sign In</OrangeArrowButton>
          </AuthActionsContainer>
        </Box>
      </Grid>
    </Auth>
  );
};

export default SignIn;