import { AppBar, styled, Toolbar } from "@mui/material";
import LogoComponent from "../../new_assets/LogoComponent";
import { StyledContainer } from "../layouts/MainLayout";
import Menu from "./Menu";
import AuthButtons from "./AuthButtons";
import { useLocation } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import UserPanel from "./UserPanel";

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isNotRoot",
})(({ theme, isNotRoot }) => ({
  background: theme.palette.transparent,
  boxShadow: isNotRoot && "0px 4px 20px 0px rgba(0, 0, 0, 0.06)",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "&": {
    minHeight: theme.spacing(10.25),
    paddingLeft: 0,
    paddingRight: 0,
  },
  justifyContent: "space-between",
}));


const NavigationBar = () => {
  const location = useLocation();
  const auth = useAuth();

  return (
    <StyledAppBar position="static" isNotRoot={location.pathname !== "/"}>
      <StyledContainer>
        <StyledToolbar>
          <LogoComponent />
          <Menu />
          {auth.isAuthenticated ? (
            <UserPanel />
          ) : <AuthButtons />}
        </StyledToolbar>
      </StyledContainer>
    </StyledAppBar>
  );
};


export default NavigationBar;