function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const objSize = Object.size(array[0]);

  result = "";

  if (array.length <= 0) {
    return;
  }
  let headerCount = 1;
  for (const property in array[0]) {
    if (headerCount === Object.size(array[0])) {
      result += property + lineDelimiter;
    } else {
      result += property + columnDelimiter;
    }
    headerCount++;
  }

  array.forEach((item) => {
    let ctr = 0;
    let actualItems = Object.values(item);
    for (let x = 0; x < objSize; x++) {
      if (ctr > 0) result += columnDelimiter;
      result += actualItems[x];
      ctr++;
    }
    result += lineDelimiter;
  });

  return result;
}

const downloadCSV = (name, array) => {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = name ? `${name}.csv` : `export.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};

Object.size = function (obj) {
  let size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

const exportCSV = {
  downloadCSV,
};

export default exportCSV;
