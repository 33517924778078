import { PreStyledMainCard } from "../../../../../new_components/cards-data-container";
import { Box, keyframes, styled } from "@mui/material";

import PureBetsPic from "../../../../../new_assets/images/pure-bets-pic.png";
import PureBetsIcon from "../../../../../new_assets/PureBetsIcon";

const StyledBrandSlider = styled(PreStyledMainCard)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
}));

const PureBetsWrap = styled(Box)(({ theme }) => ({
  width: theme.spacing(75),
  height: "fit-content",
  objectFit: "contain",
  borderWidth: 8,
  borderStyle: "solid",
  borderColor: theme.palette.surface.border.cardImage,
  borderRadius: "10px",
  position: "absolute",
  bottom: -20,
  overflow: "hidden",
}));

const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
}));

const buttonAnimation = keyframes`
  0% {
    transform: translate(30%, 250%) rotate(-8deg);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.30);
  }
  25% {
    transform: translate(30%, 70%) rotate(-8deg);
    box-shadow: 0 0 0 150px rgba(255, 255, 255, 0.30);
  }
  50% {
    transform: translate(30%, -150%) rotate(-8deg);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.30);
    opacity: 1;
  }
  100% {
    transform: translate(30%, 300%) rotate(-8deg);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.30);
    opacity: 0.5;
  }
`;

const PureBetsButtonWrap = styled(Box)(({ theme }) => ({
  zIndex: 1,
  background: theme.palette.background.pureBetsBackground,
  padding: theme.spacing(5, 9.75),
  height: "max-content",
  borderRadius: theme.spacing(3.25),
  borderWidth: 3,
  borderStyle: "solid",
  borderColor: theme.palette.surface.border.white,
  maxWidth: theme.spacing(46.25),

  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  transform: "translate(30%, 250%) rotate(-8deg)", // Start state
  boxShadow: "0px 0px 0px 0px rgba(255, 255, 255, 0.30)", // Start state
  animation: `${buttonAnimation} 5s infinite`, // Adjust time here as needed
}));

const BrandSlider = () => {
  return (
    <StyledBrandSlider>
      <PureBetsWrap>
        <StyledImage alt="pure-bets-pic" src={PureBetsPic} />
        <PureBetsButtonWrap component="div"><PureBetsIcon sx={{ fontSize: 214, height: 58 }} /></PureBetsButtonWrap>
      </PureBetsWrap>
    </StyledBrandSlider>
  );
};

export default BrandSlider;