import axios from 'axios';
import { toast } from 'react-hot-toast';

export const toastApiError = (e) => {
    if (!e) {
        toast.error("Something went wrong");
        return;
    }

    if (axios.isCancel(e)) {
        return;
    }

    const errorDetails = {
        code: e.request?.status,
        route: e.url ?? e.config?.url,
        msg: typeof e.response?.data === "string" ? e.response.data :
            e.response?.data?.message ??
            e.response?.data?.detail ??
            e.response?.data?.error ??
            e.message ??
            "Request failed"
    };

    console.error(`Failed loading${errorDetails.route ? ' ' + errorDetails.route : ''}`);
    console.error(e);

    toast.error(errorDetails.msg);
}

export default toastApiError;
