export const palette = {
  mode: "light",
  transparent: "rgba(0, 0, 0, 0)",
  background: {
    main: "#FCFBF9", // beige background
    darkBeige: "#EBE8DF", // beige dark
    medium: "#F7F4EF", // beige medium
    white: "#FFFFFF",
    darkBlue: "#0C1544",
    darkGray: "#C2C1C0",
    pureBetsBackground: "#EDECE9",
  },
  surface: {
    border: {
      main: "#E0DDD2",
      white: "#FFFFFF",
      secondary: "#F1F0EC",
      dark: "#0C1544",
      beige: "#E0DDD2",
      cardImage: "#12141D",
    },
    gradient: {
      authBackground: "linear-gradient(198deg, #FFE94D -0.05%, #FFB629 28.64%, #FF864C 57.32%, #FF774D 86%)",
      orangeBackground: "linear-gradient(81deg, #FFB629 5.89%, #FF864C 54.36%, #FF774D 100%)",
    },
  },
  primary: {
    main: "#FF9029",
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#0C1544",
    secondary: "#585D73",
    contrastText: "#EBE8DF",
  },

};
