const ConversionLevelArrow = ({ ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="6" viewBox="0 0 17 6" fill="none">
      <path id="arr"
            d="M0.992325 3.21935C0.885702 2.36636 1.18425 0.916289 3.23141 1.93987C5.27857 2.96345 7.70961 2.36636 8.66923 1.93987C9.30897 1.30014 10.8443 0.660403 11.8679 3.21935C12.8915 5.77829 15.0666 4.28557 16.0262 3.21935"
            stroke="#0C1544" strokeWidth="1.27948" />
    </svg>
  );
};

export default ConversionLevelArrow;