import { exportCSV } from "../../api";
import { Button } from "@mui/material";

const Export = ({ rows }) => {
  const exportData = () => exportCSV.downloadCSV("export", rows);
  return (
    <div>
      <Button
        size="medium"
        variant="contained"
        type="submit"
        color="primary"
        disabled={!rows || rows.length === 0}
        onClick={exportData}
      >
        Export
      </Button>
    </div>
  );
};

export default Export;
