// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 3000ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 3000ms;
}

/* .page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
 */
`, "",{"version":3,"sources":["webpack://./src/components/AdminRouter/styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,0BAA0B;AAC5B;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,0BAA0B;AAC5B;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;EA2BE","sourcesContent":[".my-node-enter {\n  opacity: 0;\n}\n.my-node-enter-active {\n  opacity: 1;\n  transition: opacity 3000ms;\n}\n.my-node-exit {\n  opacity: 1;\n}\n.my-node-exit-active {\n  opacity: 0;\n  transition: opacity 3000ms;\n}\n\n/* .page {\n  position: absolute;\n  left: 15px;\n  right: 15px;\n}\n\n.page-enter {\n  opacity: 0;\n  transform: scale(1.1);\n}\n\n.page-enter-active {\n  opacity: 1;\n  transform: scale(1);\n  transition: opacity 300ms, transform 300ms;\n}\n\n.page-exit {\n  opacity: 1;\n  transform: scale(1);\n}\n\n.page-exit-active {\n  opacity: 0;\n  transform: scale(0.9);\n  transition: opacity 300ms, transform 300ms;\n}\n */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
