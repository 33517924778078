
export const shadows = [
  "none", // elevation 0
  "0px 30.147px 24.118px 0px rgba(0, 0, 0, 0.04), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.03), 0px 4.529px 3.623px 0px rgba(0, 0, 0, 0.02)", // xs => elevation 1
  "none", // sm => elevation 2
  "none", // md => elevation 3
  "none", // lg => elevation 4
  "none", // xl => elevation 5
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
];
