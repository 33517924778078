import { Button, styled } from "@mui/material";

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})(({ theme, fullWidth }) => ({
  ...theme.typography.body1,
  textTransform: "none",
  padding: theme.spacing(1, 3),
  borderRadius: theme.spacing(3),
  boxShadow: theme.shadows[1],
  overflow: "hidden",
  width: fullWidth && "100%",
  "&::after": {
    content: "\"\"",
    display: "block",
    background: theme.palette.background.white,
    opacity: 0.2,
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: "translate(100%, 0)",
    transition: "all 300ms linear",
  },
  "&:hover": {
    "&::after": {
      transform: "translate(0%, 0)",
    },
  },
}));