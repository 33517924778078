import CommissionDescription from "./commission-description";
import CardsDataContainer from "../../../../new_components/cards-data-container";
import Graph from "./graph";
import { DataRowContainer } from "../../../../new_components/data-row-container";

const CommissionStructure = () => {
  return (
    <DataRowContainer container column>
      <CommissionDescription />
      <CardsDataContainer>
        <Graph />
      </CardsDataContainer>
    </DataRowContainer>
  );
};

export default CommissionStructure;