import {Alert, FormHelperText, Grid, Link, styled, Typography} from "@mui/material";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import OrangeArrowButton from "../../../../new_components/buttons/orange-arrow-button";
import StyledTextField from "../../../../new_components/styled-text-field";
import ReCAPTCHA from "react-google-recaptcha";
import {useCallback, useRef, useState} from "react";
import axios from "axios";
import toastApiError from "../../../../utils/toastApiError";
import * as Yup from "yup";
import { useFormik } from "formik";

const GetInTouchContainer = styled(Grid)(({ theme }) => ({
  backgroundImage: "linear-gradient(to bottom, #F7F4EF 25%, transparent 45%, transparent 70%, #F7F4EF 80%), " +
    "linear-gradient(to right, #edebe9 1px, transparent 1px), " +
    "linear-gradient(to bottom, #edebe9 1px, transparent 1px)",
  backgroundSize: "100% 100%, 105px 105px, 105px 105px",
  marginTop: theme.spacing(15.5),
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(10),
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  "@media(max-width: 1221px)": {
    // justifyContent: "center",
  },
}));

const HeadingContainer = styled(Grid)(({ theme }) => ({
  width: theme.spacing(92.75),
  // height: theme.spacing(91.25),
  height: "auto",
  aspectRatio: 1.016,
  position: "relative",
  "& > div[class*='triangle']": {
    position: "absolute",
    width: 0,
    height: 0,
    zIndex: 2,
  },
  ".top-triangle": {
    top: 0,
    left: 0,
    right: 0,
    borderLeft: "150px solid transparent",
    borderRight: "525px solid transparent",
    borderTop: `200px solid ${theme.palette.background.medium}`,
    filter: "drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.03))",
    transform: "translate(18%, 29%) rotate(345deg)",

  },
  ".right-triangle": {
    transform: "translate(0, 53%)",
    top: 0,
    bottom: 0,
    left: 0,
    borderTop: "150px solid transparent",
    borderBottom: "230px solid transparent",
    borderLeft: `280px solid ${theme.palette.background.medium}`,
    filter: "drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.03))",
  },
  ".bottom-triangle": {
    bottom: 0,
    left: 0,
    right: 0,
    borderLeft: "358px solid transparent",
    borderRight: "450px solid transparent",
    borderBottom: `303px solid ${theme.palette.background.medium}`,
    filter: "drop-shadow(-3px -1px 3px rgba(0, 0, 0, 0.03))",
  },
  "@media(max-width: 1221px)": {
    zoom: 0.8,
  },
  "@media(max-width: 705px)": {
    zoom: 0.4,
  },
}));

const FormSideContainer = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(60),
}));

const FormContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(3),
}));

const initialFormValues = {
  company: "",
  name: "",
  email: "",
  message: "",
  recaptcha: ""
};

const GetInTouchSchema = Yup.object({
  company: Yup.string(),
  name: Yup.string(),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  message: Yup.string().required("Message is required"),
  recaptcha: Yup.string().required("ReCaptcha is required"),
});

const GetInTouch = () => {
  const [captcha, setCaptcha] = useState(false);
  const [erCaptcha, setErCaptcha] = useState(false);
  const reCaptchaRef = useRef(null);
  const [success, setSuccess] = useState(false);

  const submitForm = (values) => {
    setErCaptcha(false);
    if (captcha) {
      try {
        const { recaptcha, ...rest } = values;
        axios.post(`/api/v3/messages/contact-us`, {
          ...rest,
          gRecaptchaResponse: captcha,
        }).then(r => {
          setSuccess(true);
        })

      } catch (err) {
        setSuccess(false);
        toastApiError(err);
      } finally {
        reCaptchaRef.current?.reset();
        formik.resetForm();
      }
    } else {
      setErCaptcha(true);
    }
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: GetInTouchSchema,
    onSubmit: submitForm,
  });

  const handleCaptchaChange = useCallback((value) => {
    setCaptcha(value);
    formik.setFieldValue("recaptcha", value);
  }, [formik.setFieldValue]);

  return (
    <GetInTouchContainer container mt={15.5} justifyContent="space-between" alignItems="center">
      <Grid item>
        <HeadingContainer container justifyContent="center" alignItems="center" height="100%">
          <div className="top-triangle"></div>
          <div className="right-triangle"></div>
          <div className="bottom-triangle"></div>
          <Typography variant="getInTouchHeading">Get
            in <br /><UnderlinedHeaderTextWord>touch</UnderlinedHeaderTextWord></Typography>
        </HeadingContainer>
      </Grid>
      <Grid item>
        <FormSideContainer container>
          {success && (
              <Alert severity="success">
                Your message has been sent, we’ll get back to you within 24 hours
              </Alert>
          )}
          <Typography variant="subtitle2">You can reach our dedicated affiliate team via our live chat system or through
            the contact form below. Alternatively, please email{" "}
            <Link href="mailto:affiliates@vegasaffiliate.com" target="_blank"
                  rel="noopener noreferrer">affiliates@vegasaffiliate.com</Link>
          </Typography>
          <FormContainer>
            <div>
              <StyledTextField
                  name="company"
                  label="Company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.company && Boolean(formik.errors.company)}
                  helperText={formik.touched.company && formik.errors.company}
                  variant="filled" fullWidth/>
            </div>
            <div>
              <StyledTextField
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant="filled" fullWidth/>
            </div>
            <div>
              <StyledTextField
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  required variant="filled" fullWidth/>
            </div>
            <div>
              <StyledTextField
                  name="message"
                  label="Message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                  required variant="filled" fullWidth
              />
            </div>
            <div className="contact__form_recaptcha">
              <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey="6Lfk6tQpAAAAANeQHyiiY7FOZj7Xf-ZosDzq2eAL"
                  onChange={handleCaptchaChange}
              />
              {formik.touched.recaptcha && formik.errors.recaptcha && (
                  <FormHelperText
                      error={formik.touched.recaptcha && Boolean(formik.errors.recaptcha)}>{formik.errors.recaptcha}</FormHelperText>
              )}
              {erCaptcha && <FormHelperText
                  error={erCaptcha}>ReCaptcha error</FormHelperText>}
            </div>
            <OrangeArrowButton
                onClick={() => formik.submitForm()}
                sx={{ alignSelf: "flex-end" }}>Send message</OrangeArrowButton>
          </FormContainer>
        </FormSideContainer>
      </Grid>
    </GetInTouchContainer>
  );
};

export default GetInTouch;