import { useState } from "react";
import { Box, Dialog, Grid, styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "../Menu";
import LogoComponent from "../../../new_assets/LogoComponent";
import { CloseRounded } from "@mui/icons-material";
import { StyledIconButton } from "../../buttons/styled-icon-button";
import UserPanel from "../UserPanel";
import useAuth from "../../../auth/useAuth";
import AuthButtons from "../AuthButtons";

const MobileNavigationBarContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
  padding: theme.spacing(1.5),
  alignItems: "center",
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: theme.palette.background.medium,
    borderRadius: theme.spacing(3),
  },
}));

const MobileNavigationBar = () => {
  const auth = useAuth();
  const [open, setOpen] = useState(false);

  const toggleDialog = (value) => () => {
    setOpen(value ? value : !open);
  };

  return (
    <MobileNavigationBarContainer container>
      <StyledIconButton sx={{ zIndex: open ? 1400 : undefined }} onClick={toggleDialog()}>
        {open ? <CloseRounded /> : <MenuIcon />}
      </StyledIconButton>
      <LogoComponent />
      {auth.isAuthenticated && <UserPanel />}
      <StyledDialog
        fullWidth
        maxWidth="sx"
        open={open}
        onClose={toggleDialog(false)}
      >
        <Box
          sx={{ flexGrow: 1 }}
          role="presentation"
          onClick={toggleDialog(false)}
          onKeyDown={toggleDialog(false)}
        >
          <Menu />
          {!auth.isAuthenticated && <AuthButtons mt={16.5} mb={4} justifyContent="center" />}
        </Box>
      </StyledDialog>
    </MobileNavigationBarContainer>
  );
};

export default MobileNavigationBar;