import { styled, Typography } from "@mui/material";
import CardsDataContainer from "../../../../new_components/cards-data-container";
import BrandSlider from "./brand-slider";
import { DataRowContainer } from "../../../../new_components/data-row-container";
import { DescriptionSide } from "../../../../new_components/data-row-container/description-side";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import OrangeArrowButton from "../../../../new_components/buttons/orange-arrow-button";
import {Link} from "react-router-dom";

const OurBrandContainer = styled(DataRowContainer)(({ theme }) => ({
  padding: theme.spacing(6, 0, 11),
  [theme.breakpoints.down("lg")]: {
    padding: 0,
    flexDirection: "column-reverse",
  },
}));

const OurBrand = () => {
  return (
    <OurBrandContainer container>
      <CardsDataContainer>
        <BrandSlider />
      </CardsDataContainer>
      <DescriptionSide gap={3} alignItems="flex-start">
        <Typography variant="h2">Our <UnderlinedHeaderTextWord>Brand</UnderlinedHeaderTextWord></Typography>
        <Typography variant="subtitle2">PureBets is a Curacao-licensed brand. We offer top-notch
          selection of casino slots, live casino, sports, and live betting.</Typography>
        <OrangeArrowButton component={Link} to="https://purebets.com">Visit Website</OrangeArrowButton>
      </DescriptionSide>
    </OurBrandContainer>
  );
};

export default OurBrand;