import { useEffect, useState } from "react";
import { ReportContext } from "../HOC/ReportContext";
import { Popover, Stack } from "@mui/material";
import fetchData from "../../api/data";

const BrandSort = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    return fetchData({
      url: "/api/v3/brands/all",
      onSuccess: (response) => setOptions(response.data),
    });
  }, []);

  const showPopover = (event) => setAnchorEl(event.currentTarget);
  const hidePopover = () => setAnchorEl(null);

  return (
    <ReportContext.Consumer>
      {context => (
        <div className="brandsSelect">
          <div className="brandsContainer">
            <button
              className="toggleBrandSelect"
              onClick={showPopover}
            >
              {context.brands.length > 0
                ? `${context.brands.length} casino selected`
                : "Filter by Casino"}
            </button>
            <Popover
              open={anchorEl !== null}
              anchorEl={anchorEl}
              onClose={hidePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Stack sx={{ p: 2 }}>
                {options && options.map(({ id, name }, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      name="brand"
                      value={id}
                      className="brands-checkbox"
                      checked={context.brands.includes(id)}
                      onChange={(e) =>
                        e.target.checked
                          ? context.updateContext({ ...context, brands: [...context.brands, id] }) // FIXME duplicates
                                                                                                   // possible or not?
                          : context.updateContext({ ...context, brands: context.brands.filter(b => b !== id) })
                      }
                    />
                    {" "}
                    {name}
                  </label>
                ))}
                <button
                  className="resetBrandList"
                  sx={{ mx: "auto" }}
                  onClick={() => context.updateContext({ ...context, brands: [] })}
                >
                  Clear
                </button>
              </Stack>
            </Popover>
          </div>
        </div>
      )}
    </ReportContext.Consumer>
  );
};

export default BrandSort;
