import { SvgIcon } from "@mui/material";

const PictureIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
      <path
        d="M25.2286 18.9389L25.4346 19.5729C25.7759 20.6235 25.201 21.7518 24.1505 22.0931L8.93361 27.0374C7.88309 27.3787 6.7548 26.8038 6.41347 25.7533L3.11729 15.6087C2.77595 14.5582 3.35084 13.4299 4.40136 13.0885L5.0354 12.8825L7.71355 21.125C8.28152 22.8731 10.1658 23.8331 11.9138 23.2651L25.2286 18.9389ZM28.0028 14.5327L24.7066 4.38805C24.3652 3.33753 23.2369 2.76263 22.1864 3.10397L6.96952 8.04824C5.919 8.38958 5.34411 9.51787 5.68544 10.5684L8.98163 20.713C9.32296 21.7635 10.4513 22.3384 11.5018 21.9971L26.7187 17.0528C27.7692 16.7115 28.3441 15.5832 28.0028 14.5327ZM12.6439 10.4104C12.9852 11.4609 12.4103 12.5892 11.3598 12.9305C10.3093 13.2719 9.18096 12.697 8.83963 11.6465C8.49829 10.5959 9.07319 9.46764 10.1237 9.12631C11.1742 8.78497 12.3025 9.35987 12.6439 10.4104ZM10.6937 17.3528L12.1788 14.4381C12.3042 14.1921 12.6053 14.0942 12.8514 14.2196L14.926 15.2767L18.5512 8.16173C18.6766 7.9157 18.9777 7.81787 19.2237 7.94321L23.8185 10.2844L25.2606 14.7227L11.3118 19.2549L10.6937 17.3528Z"
        fill="black" />
    </SvgIcon>
  );
};

export default PictureIcon;