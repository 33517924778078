import { Navigate, useLocation } from "react-router-dom";

const Referral = () => {
  const location = useLocation();
  const referral_code = location.pathname.split("/")[2];
  document.cookie = `referral_code=${referral_code}`;
  return <Navigate to="/sign-up" />;
};

export default Referral;
