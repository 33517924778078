import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../new_pages/home";
import MainLayout from "../new_components/layouts/MainLayout";
import FAQs from "../new_pages/faq";
import SignUp from "../new_pages/auth/sign-up";
import SignIn from "../new_pages/auth/sign-in";
import ResetPassword from "../new_pages/auth/reset-password";
import ForgotPassword from "../new_pages/auth/forgot-password";
import ReactGA from "react-ga";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "../auth/JWTContext";
import { Terms } from "../pages/Terms";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { Referral } from "../pages/Referral";
import { PrivateRoute } from "./PrivateRoute";
import AdminRouter from "../components/AdminRouter/AdminRouter";
import Reviews from "../new_pages/home/components/reviews";

const createRouteWithLayout = (path, Element) => {
  return <Route path={path} element={<MainLayout><Element /></MainLayout>} />;
};

const AdminComponent = () => {
  return (
    <PrivateRoute>
      <AdminRouter />
    </PrivateRoute>
  );
};


const AppRouter = () => {
  ReactGA.initialize("UA-61473152-2");
  ReactGA.pageview(window.location.pathname);

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {createRouteWithLayout("/", Home)}
          {createRouteWithLayout("/faqs", FAQs)}
          {createRouteWithLayout("/sign-up", SignUp)}
          {createRouteWithLayout("/sign-in", SignIn)}
          {createRouteWithLayout("/forgot", ForgotPassword)}
          {createRouteWithLayout("/reset_password", ResetPassword)}
          {createRouteWithLayout("/terms", Terms)}
          {createRouteWithLayout("/privacy-policy", PrivacyPolicy)}
          {createRouteWithLayout("/admin/*", AdminComponent)}
          {createRouteWithLayout("/r/:referral_code", Referral)}
          {createRouteWithLayout("/testimonials", Reviews)}
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRouter;