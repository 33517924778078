import { styled, TextField } from "@mui/material";

const StyledTextFieldComponent = styled(TextField)(({ theme }) => ({
  "& > div": {
    background: theme.palette.background.white,
    borderRadius: theme.spacing(1),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.transparent,
    "&::before": {
      border: "none",
    },
    "&::after": {
      display: "none",
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      background: theme.palette.background.white,
      "&:not(.Mui-disabled, .Mui-error):before": {
        borderBottom: "none",
      },
    },
    "&.Mui-focused": {
      borderColor: theme.palette.primary.main,
      background: theme.palette.background.white,
    },
  },
}));

const StyledTextField = ({ ...props }) => {
  return (
    <StyledTextFieldComponent {...props} />
  );
};

export default StyledTextField;