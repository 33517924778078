import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import calendar from "../../assets/calendar.png";
import moment from "moment";
import "./date-picker.css";
import { ReportContext } from "../HOC/ReportContext";
import { Popover } from "@mui/material";
import { useState } from "react";

export const DatePicker = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const showPopover = (event) => setAnchorEl(event.currentTarget);
  const hidePopover = () => setAnchorEl(null);

  function formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function changeDates(context, event) {
    context.updateContext({
      ...context,
      startDate: moment(event.selection.startDate).format("YYYY-MM-DD"),
      endDate: moment(event.selection.endDate).format("YYYY-MM-DD"),
    });
  }

  return (
    <ReportContext.Consumer>
      {(context) => (
        <div className="date-picker-container">
          <button
            className="toggleDatePicker"
            onClick={showPopover}
          >
            <img src={calendar} alt="calendar" />
            <span className="startDate">
              {formatDate(context.startDate)}
            </span>
            -
            <span className="endDate">
              {formatDate(context.endDate)}
            </span>
          </button>
          <Popover
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={hidePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <DateRangePicker
              onChange={(event) => changeDates(context, event)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={[{
                startDate: new Date(context.startDate),
                endDate: new Date(context.endDate),
                key: "selection",
              }]}
              direction="horizontal"
            />
          </Popover>
        </div>
      )}
    </ReportContext.Consumer>
  );
};
