import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

const baseOptions = {
  direction: "ltr",
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          gap: theme.spacing(1),
        }),
        endIcon: ({ ownerState, theme }) => ({
          marginLeft: 0,
        }),
      },
    },
  },
  typography,
};

const themeOptions = {
  palette,
  shadows,
};

export const customTheme = (config = {}) => {
  let theme = createTheme({
    ...baseOptions,
    ...themeOptions,
    direction: config.direction,
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
