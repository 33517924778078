import React from "react";
import { DatePicker } from "../DatePicker/DatePicker";
import BrandSort from "../BrandSort/BrandSort";
import { ReportContext } from "./ReportContext";
import moment from 'moment';

const connectTopLine = (isDate: boolean, isBrand: boolean, Component: any) => {

  function formatDate(date: Date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function formatDates(startDate: Date, endDate: Date) {
    return `date_from=${formatDate(startDate)}&date_to=${formatDate(endDate)}`
  }

  function formatBrands(selectedBrands: number[]) {
    return 'brand_ids=' + selectedBrands.join(',');
  }

  return (props: any) => (
    <ReportContext.Consumer>
      {context => (
        <>
          <div className="top-line">
            {/* Using visibility to avoid content jumping by a few pixels between pages (i.e. keep the size constant) */}
            <div className="datepicker__col" style={{ visibility: isDate ? 'visible' : 'hidden' }}><DatePicker /></div>
            <div className="brandpicker__col">{isBrand && <BrandSort />}</div>
          </div>
          <Component
            {...props}
            datesFilter={formatDates(context.startDate, context.endDate)}
            brandsFilter={formatBrands(context.brands)}
          />
        </>
      )}
    </ReportContext.Consumer>
  );
};

export default connectTopLine;
