import { useEffect, useState } from "react";
import Title from "../../components/Title/Title";
import connectTopLine from "../../components/HOC/connectTopLine";
import "./assets.scss";
import fetchData from "../../api/data";

const Assets = () => {
  const [assets, setAssets] = useState(null);

  useEffect(() => {
    return fetchData({
      url: "/api/v3/default_assets/all",
      onSuccess: (response) => setAssets(response.data),
    });
  }, []);

  return (
    <>
      <Title title="Assets" />
      <div className="assets-list">
        {assets
          ? assets.map((asset) => {
              return (
                <div key={asset.id} className="asset">
                  <div className="asset-description">
                    <span className="casino-title">{`${asset.brandName}`}</span>
                  </div>
                  <a href={asset.imageData} target="_blank" rel="noopener noreferrer">
                    <img className="asset-image" src={asset.imageData} alt={asset.brandName} />
                  </a>
                </div>
              );
            },
          )
          : "Loading..."}
      </div>
    </>
  );
};

export default connectTopLine(false, false, Assets);
