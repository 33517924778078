import { FormControlLabel } from "@mui/material";

const CustomFormControlLabel = ({ ...props }) => {
  return <FormControlLabel slotProps={{
    typography: {
      variant: "subtitle2",
    },
  }} {...props} />;
};

export default CustomFormControlLabel;