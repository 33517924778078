import { SvgIcon } from "@mui/material";

const PictureIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" fill="#333" width="24" height="24"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 477.867 477.867" style={{ enableBackground: "new 0 0 477.867 477.867" }} xmlSpace="preserve">
      <g>
        <g>
          <path d="M426.667,68.267H51.2c-28.277,0-51.2,22.923-51.2,51.2V358.4c0,28.277,22.923,51.2,51.2,51.2h375.467
			c28.277,0,51.2-22.923,51.2-51.2V119.467C477.867,91.19,454.944,68.267,426.667,68.267z M443.733,266.001L336.333,158.601
			c-6.664-6.663-17.468-6.663-24.132,0L170.667,300.134l-56.201-56.201c-6.664-6.663-17.468-6.663-24.132,0l-56.201,56.201V119.467
			c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067V266.001z" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="153.6" cy="187.733" r="51.2" />
        </g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </SvgIcon>


  );
};

export default PictureIcon;