import { SvgIcon } from "@mui/material";

const CalendarIcon = ({ ...props }) => {
  return (
    <SvgIcon id="Capa_1" {...props} enableBackground="new 0 0 512 512" height="24" fill="#333" viewBox="0 0 512 512"
             width="24"
             xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="m357.314 497h-317.506c-9.649 0-17.5-7.851-17.5-17.5v-169.043c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v169.043c0 17.921 14.579 32.5 32.5 32.5h317.506c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z" />
        <path
          d="m472.192 48.95h-36.352v-17.724c0-17.218-14.008-31.226-31.227-31.226-17.218 0-31.226 14.008-31.226 31.226v17.724h-234.775v-17.724c0-17.218-14.008-31.226-31.225-31.226-17.219 0-31.227 14.008-31.227 31.226v17.724h-36.352c-17.921 0-32.5 14.579-32.5 32.5v196.321c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-106.02h157.436c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-157.436v-75.301c0-9.649 7.851-17.5 17.5-17.5h36.352v17.725c0 17.218 14.008 31.226 31.227 31.226 17.218 0 31.226-14.008 31.226-31.226v-17.725h234.775v17.725c0 17.218 14.008 31.226 31.226 31.226 17.219 0 31.227-14.008 31.227-31.226v-17.725h36.353c9.649 0 17.5 7.851 17.5 17.5v75.302h-277.264c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h277.263v307.748c0 9.649-7.851 17.5-17.5 17.5h-82.775c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h82.775c17.921 0 32.5-14.579 32.5-32.5v-398.05c-.001-17.921-14.58-32.5-32.501-32.5zm-348.58 32.725c0 8.947-7.278 16.226-16.226 16.226-8.947 0-16.227-7.278-16.227-16.226v-50.449c.001-8.948 7.28-16.226 16.228-16.226 8.947 0 16.226 7.278 16.226 16.226v50.449zm297.228 0c0 8.947-7.279 16.226-16.227 16.226-8.947 0-16.226-7.278-16.226-16.226v-50.449c0-8.947 7.278-16.226 16.226-16.226 8.947 0 16.227 7.278 16.227 16.226z" />
        <path
          d="m224.065 305.435h63.869c9.649 0 17.5-7.851 17.5-17.5v-63.869c0-9.649-7.851-17.5-17.5-17.5h-63.869c-9.649 0-17.5 7.851-17.5 17.5v63.869c0 9.649 7.851 17.5 17.5 17.5zm-2.5-81.37c0-1.379 1.121-2.5 2.5-2.5h63.869c1.379 0 2.5 1.121 2.5 2.5v63.869c0 1.379-1.121 2.5-2.5 2.5h-63.869c-1.379 0-2.5-1.121-2.5-2.5z" />
        <path
          d="m87.065 305.435h63.869c9.649 0 17.5-7.851 17.5-17.5v-63.869c0-9.649-7.851-17.5-17.5-17.5h-63.869c-9.649 0-17.5 7.851-17.5 17.5v63.869c0 9.649 7.851 17.5 17.5 17.5zm-2.5-81.37c0-1.379 1.121-2.5 2.5-2.5h63.869c1.379 0 2.5 1.121 2.5 2.5v63.869c0 1.379-1.121 2.5-2.5 2.5h-63.869c-1.379 0-2.5-1.121-2.5-2.5z" />
        <path
          d="m206.565 429.824c0 9.649 7.851 17.5 17.5 17.5h63.869c9.649 0 17.5-7.851 17.5-17.5v-63.869c0-9.649-7.851-17.5-17.5-17.5h-63.869c-9.649 0-17.5 7.851-17.5 17.5zm83.87-8.108-23.827-23.827 23.827-23.827zm-10.607 10.608h-47.656l23.828-23.827zm-23.828-45.042-23.827-23.827h47.654zm-10.607 10.607-23.827 23.827v-47.653z" />
        <path
          d="m364.179 305.435h63.87c9.649 0 17.5-7.851 17.5-17.5v-63.869c0-9.649-7.851-17.5-17.5-17.5h-63.87c-9.649 0-17.5 7.851-17.5 17.5v63.869c0 9.649 7.85 17.5 17.5 17.5zm-2.5-81.37c0-1.379 1.121-2.5 2.5-2.5h63.87c1.379 0 2.5 1.121 2.5 2.5v63.869c0 1.379-1.121 2.5-2.5 2.5h-63.87c-1.379 0-2.5-1.121-2.5-2.5z" />
        <path
          d="m346.679 429.824c0 9.649 7.851 17.5 17.5 17.5h63.87c9.649 0 17.5-7.851 17.5-17.5v-63.869c0-9.649-7.851-17.5-17.5-17.5h-63.87c-9.649 0-17.5 7.851-17.5 17.5zm83.87-8.107-23.828-23.828 23.828-23.828zm-10.608 10.607h-47.655l23.828-23.828zm-23.827-45.042-23.827-23.827h47.653zm-10.608 10.607-23.828 23.828v-47.655z" />
        <path
          d="m69.565 429.824c0 9.649 7.851 17.5 17.5 17.5h63.869c9.649 0 17.5-7.851 17.5-17.5v-63.869c0-9.649-7.851-17.5-17.5-17.5h-63.869c-9.649 0-17.5 7.851-17.5 17.5zm15-63.869c0-1.379 1.121-2.5 2.5-2.5h63.869c1.379 0 2.5 1.121 2.5 2.5v63.869c0 1.379-1.121 2.5-2.5 2.5h-63.869c-1.379 0-2.5-1.121-2.5-2.5z" />
      </g>
    </SvgIcon>
  );
};

export default CalendarIcon;