import { Grid, styled, Typography } from "@mui/material";
import UnderlinedHeaderTextWord from "../../../../new_components/underlined-header-text-word";
import { StyledChip } from "../../../../new_components/chip/styled-chip";
import OrangeArrowButton from "../../../../new_components/buttons/orange-arrow-button";

import step1 from "../../../../new_assets/images/how-it-works/step1.png";
import step2 from "../../../../new_assets/images/how-it-works/step2.png";
import step3 from "../../../../new_assets/images/how-it-works/step3.png";
import { useEffect, useRef, useState } from "react";
import {Link} from "react-router-dom";

const HowItWorksContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(21.5),
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(10),
  },
}));

const StyledCard = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  padding: theme.spacing(4.625, 5),
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.surface.border.secondary,
  borderRadius: theme.spacing(2),
  background: theme.palette.background.white,
  width: "100%",
  maxWidth: theme.spacing(52),
  height: theme.spacing(51.5),
  position: "relative",
  overflow: "hidden",
}));

const StepImg = styled("img")(({ theme }) => ({
  maxWidth: theme.spacing(41.75),
  position: "absolute",
  top: theme.spacing(4.625),
  transition: "transform 0.5s ease-in-out",
  transform: `translate(0px, ${theme.spacing(47.5)})`,
}));

const CardContent = styled(Grid)(({ theme }) => ({
  minHeight: theme.spacing(12),
  zIndex: 1,
  boxShadow: "0px -40px 64px 0px rgba(255, 255, 255, 1)",
  background: theme.palette.background.white,
}));

const CardTitle = styled(Grid)(({ theme }) => ({
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme.palette.surface.border.dark,
  height: "max-content",
}));

const HowItWorks = () => {
  const [isVisibleStep1, setIsVisibleStep1] = useState(false);
  const [isVisibleStep2, setIsVisibleStep2] = useState(false);
  const [isVisibleStep3, setIsVisibleStep3] = useState(false);
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          switch (entry.target.id) {
            case "step1":
              setIsVisibleStep1(true);
              observer.unobserve(entry.target);
              break;
            case "step2":
              setIsVisibleStep2(true);
              observer.unobserve(entry.target);
              break;
            case "step3":
              setIsVisibleStep3(true);
              observer.unobserve(entry.target);
              break;
          }
        }
      });
    }, observerOptions);

    if (step1Ref.current) {
      observer.observe(step1Ref.current);
    }
    if (step2Ref.current) {
      observer.observe(step2Ref.current);
    }
    if (step3Ref.current) {
      observer.observe(step3Ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <HowItWorksContainer container>
      <Grid item xs={12} mb={5.5} alignContent="center">
        <Typography variant="h2" textAlign="center">How
          it <UnderlinedHeaderTextWord>works</UnderlinedHeaderTextWord></Typography>
      </Grid>
      <Grid item xs={12} gap={4} display="flex" flexDirection="column">
        <Grid container gap={2} justifyContent="center">
          <StyledCard item ref={step1Ref} id="step1">
            <StepImg src={step1} alt="step1" sx={{
              transform: isVisibleStep1 && "translate(0px, 0px)",
            }} />
            <CardContent container mt="auto">
              <CardTitle container justifyContent="space-between">
                <Typography variant="subtitle1">Join</Typography>
                <StyledChip label="Step 1" />
              </CardTitle>
              <Typography variant="subtitle2" mt={2}>Join Vegas Affiliate Network today and start earning
                today.</Typography>
            </CardContent>
          </StyledCard>

          <StyledCard item ref={step2Ref} id="step2">
            <StepImg src={step2} alt="step2" sx={{
              transform: isVisibleStep2 && "translate(0px, 0px)",
            }} />
            <CardContent container mt="auto">
              <CardTitle container justifyContent="space-between">
                <Typography variant="subtitle1">Promote</Typography>
                <StyledChip label="Step 2" />
              </CardTitle>
              <Typography variant="subtitle2" mt={2}>Get immediate access to a range of marketing
                materials.</Typography>
            </CardContent>
          </StyledCard>

          <StyledCard item ref={step3Ref} id="step3">
            <StepImg src={step3} alt="step3" sx={{
              transform: isVisibleStep3 && "translate(0px, 0px)",
            }} />
            <CardContent container mt="auto">
              <CardTitle container justifyContent="space-between">
                <Typography variant="subtitle1">Earn</Typography>
                <StyledChip label="Step 3" />
              </CardTitle>
              <Typography variant="subtitle2" mt={2}>Watch your traffic turn into cash.</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <OrangeArrowButton component={Link} to="/sign-up">Sign Up</OrangeArrowButton>
          </Grid>
        </Grid>
      </Grid>
    </HowItWorksContainer>
  );
};

export default HowItWorks;