import { Grid, Link, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { StyledIconButton } from "../../new_components/buttons/styled-icon-button";

const AuthContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.surface.gradient.authBackground,
  [theme.breakpoints.down("lg")]: {
    background: theme.palette.background.darkGray,
  },
  backgroundSize: "100%",
  height: "100vh",
}));

const AuthChildrenContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(8.5, 1.5, 6),
  },
}));

const FormWrap = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.medium,
  borderTopLeftRadius: theme.spacing(3),
  borderBottomLeftRadius: theme.spacing(3),
  [theme.breakpoints.down("lg")]: {
    borderRadius: theme.spacing(3),
  },
  height: "100vh",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(4.5),
  paddingRight: theme.spacing(8),
  [theme.breakpoints.down("lg")]: {
    padding: 0,
  },
  overflow: "auto",
  position: "relative",
}));

const FormContainer = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(60),
}));

export const Star = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const AuthActionsContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

const Auth = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <AuthContainer container>
      <Grid item width="100%" display="flex" justifyContent="flex-end">
        {!isMobile && <Grid container justifyContent="center" alignItems="center" xs={6}>
          <Typography component="h1" variant="cardBackgroundText" color="background.white">Welcome
            to <br /> Vegas Affiliate!</Typography>
        </Grid>}
        <FormWrap container>
          <StyledIconButton sx={theme => ({
            position: "fixed",
            top: theme.spacing(3),
            right: theme.spacing(8),
          })} component={Link} href="/">
            <CloseRounded />
          </StyledIconButton>
          <Grid item width="100%" display="flex" justifyContent="center">
            <FormContainer container justifyContent="center">
              <AuthChildrenContainer item>
                {children}
              </AuthChildrenContainer>
            </FormContainer>
          </Grid>
        </FormWrap>
      </Grid>
    </AuthContainer>
  );
};

export default Auth;