import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import WhiteButton from "../buttons/white-button";
import OrangeArrowButton from "../buttons/orange-arrow-button";


const AuthButtons = ({ ...props }) => {
  return (
    <Box display="flex" gap={1.375} {...props}>
      <WhiteButton component={Link} to="/sign-in">Sign In</WhiteButton>
      <OrangeArrowButton component={Link} to="/sign-up">Sign Up</OrangeArrowButton>
    </Box>
  );
};

export default AuthButtons;