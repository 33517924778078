import { SvgIcon } from "@mui/material";

const DescIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="37" height="34" viewBox="0 0 37 34" fill="none">
      <path
        d="M29.4587 13.2274L23.8154 28.7324C23.3904 29.9001 22.0992 30.5021 20.9315 30.0771L8.24567 25.4599C7.07795 25.0348 6.47589 23.7437 6.90091 22.576L12.5442 7.07108C12.9693 5.90337 14.2604 5.30131 15.4281 5.72632L18.9519 7.00889C19.5177 5.45444 21.2426 4.6501 22.7971 5.21588C24.3515 5.78165 25.1559 7.50656 24.5901 9.06102L28.1139 10.3436C29.2817 10.7686 29.8837 12.0597 29.4587 13.2274ZM22.1558 6.9778C21.5719 6.76529 20.9264 7.06632 20.7139 7.65018C20.5014 8.23404 20.8024 8.8796 21.3862 9.09211C21.9701 9.30462 22.6157 9.00358 22.8282 8.41973C23.0407 7.83587 22.7396 7.19031 22.1558 6.9778ZM24.5567 13.5384L24.8774 12.6574C24.9029 12.5873 24.8995 12.51 24.868 12.4424C24.8365 12.3748 24.7794 12.3224 24.7093 12.2969L16.7806 9.41113C16.7105 9.38562 16.6332 9.389 16.5656 9.42052C16.498 9.45205 16.4457 9.50914 16.4201 9.57923L16.0995 10.4602C16.074 10.5303 16.0774 10.6076 16.1089 10.6752C16.1404 10.7429 16.1975 10.7952 16.2676 10.8207L24.1963 13.7065C24.2663 13.732 24.3437 13.7286 24.4113 13.6971C24.4789 13.6656 24.5312 13.6085 24.5567 13.5384Z"
        fill="black" />
    </SvgIcon>
  );
};

export default DescIcon;